import { Action } from 'redux'
import {
    AUTHENTICATE_USER,
    GLOBAL_APPLICATION_DATA,
    ERROR,
    SIDEMENU,
} from '../actions/appContextActions'

export interface ReduxAction extends Action {
    payload: any
}

const initialState: any = {
    authuser: {},
    sidemenu: {},
    errorState: {},
    globalApplicationData: {},
}

export const AppContextReducer = (state: any, action: ReduxAction) => {
    switch (action.type) {
        case AUTHENTICATE_USER:
            return {
                ...state,
                authuser: action.payload,
            }
        case GLOBAL_APPLICATION_DATA:
            return {
                ...state,
                globalApplicationData: action.payload,
            }
        case SIDEMENU:
            return {
                ...state,
                sidemenu: action.payload,
            }
        case ERROR:
            return {
                ...state,
                errorState: action.payload,
            }
        default:
            return initialState
    }
}
