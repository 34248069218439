export const validationRules = (fieldName: string) => {
    var rules:any = {
        emailAddress: {
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: ' is Invalid',
            },
            maxLength: {
                value: 100,
                message: ' should be less than 100 Characters.',
            },
            allowed: ['special_Characters'],
        },
        local_phoneNumber: {
            minLength: {
                value: 10,
                message: ' should be 10 digits.',
            },
            maxLength: {
                value: 10,
                message: ' should be 10 digits.',
            },
        },
        int_phoneNumber: {
            minLength: {
                value: 6,
                message: ' should be more than 6 digits.',
            },
            maxLength: {
                value: 13,
                message: ' should be less than 15 digits.',
            },
        },
        resident_phoneNumber_R: {
            minLength: {
                value: 12,
                message: ' should be 12 digits.',
            },
            maxLength: {
                value: 12,
                message: ' should be 12 digits.',
            },
        },

        resident_phoneNumber_NR: {
            minLength: {
                value: 13,
                message: ' should be 13 digits.',
            },
            maxLength: {
                value: 13,
                message: ' should be 13 digits.',
            },
        },
        home_phoneNumber: {
            minLength: {
                value: 13,
                message: ' min 13 digits.',
            },
            maxLength: {
                value: 16,
                message: ' max 16 digits.',
            },
        },
        communication_phoneNumber: {
            minLength: {
                value: 9,
                message: ' min 9 digits.',
            },
            maxLength: {
                value: 12,
                message: ' max 12 digits.',
            },
        },

        firstName: {
            maxLength: {
                value: 100,
                message: ' should be less than 100 Characters.',
            },
            notAllowed: ['numbers'],
            pattern: {
                value: /^[a-zA-Z ]{1,100}$/i,
                message: ' is Invalid',
            },
        },
        middleName: {
            maxLength: {
                value: 100,
                message: ' should be less than 100 Characters.',
            },
            pattern: {
                value: /^[a-zA-Z ]{0,100}$/i,
                message: ' is Invalid',
            },
            notAllowed: ['numbers'],
        },
        lastName: {
            maxLength: {
                value: 100,
                message: ' should be less than 100 Characters.',
            },
            pattern: {
                value: /^[a-zA-Z ]{1,100}$/i,
                message: ' is Invalid',
            },
            notAllowed: ['numbers'],
        },
        initial: {
            maxLength: {
                value: 50,
                message: ' should be less than 50 Characters.',
            },
        },
        userName: {
            maxLength: {
                value: 50,
                message: ' should be less than 50 Characters.',
            },
            notAllowed: ['space'],
        },
        staffid: {
            maxLength: {
                value: 10,
                message: ' should be less than 10 Characters.',
            },
        },
        group: {
            maxLength: {
                value: 50,
                message: ' should be less than 50 Characters.',
            },
        },
        comments: {
            maxLength: {
                value: 250,
                message: ' should be less than 250 Characters.',
            },
            allowed: ['all_special_Characters'],
        },
        password: {
            maxLength: {
                value: 30,
                message: ' should be less than 30 Characters.',
            },
            allowed: ['all_special_Characters'],
        },
        long_comments: {
            maxLength: {
                value: 1000,
                message: ' should be less than 1000 Characters.',
            },
            allowed: ['all_special_Characters'],
        },
        emiratesID: {
            pattern: {
                value: /^784/,
                message: ' should start with 784',
            },
            minLength: {
                value: 15,
                message: ' should be 15 Characters.',
            },
            maxLength: {
                value: 15,
                message: ' should be 15 Characters.',
            },
            notAllowed: ['space'],
        },
        passportNo: {
            pattern: {
                value: /^[a-zA-Z0-9]{0,10}$/,
                message: ' is Invalid',
            },
            minLength: {
                value: 5,
                message: ' be more than 5 Characters.',
            },
            maxLength: {
                value: 10,
                message: ' should be less than 10 Characters.',
            },
            notAllowed: ['space'],
        },
        incomePerMonth: {
            pattern: {
                value: /^[1-9][0-9]*$/,
                message: ' should be greater 0',
            },
            maxLength: {
                value: 15,
                message: ' should be less than 15 Characters.',
            },
            notAllowed: ['space'],
        },
        rentalPerMonth: {
            pattern: {
                value: /^^[1-9][0-9]*$/,
                message: ' per Month should be greater 0',
            },
            maxLength: {
                value: 15,
                message: ' per Month should be less than 15 Characters.',
            },
            notAllowed: ['space'],
        },
        propertyAddress: {
            maxLength: {
                value: 250,
                message: ' should be less than 250 Characters.',
            },
            allowed: ['all_special_Characters'],
        },
        lengthOfServiceBusiness: {
            maxLength: {
                value: 3,
                message: ' should be less than 3 Digit.',
            },
        },
        percentageValue: {
            maxLength: {
                value: 3,
                message: ' should be less than 3 Digit.',
            },
        },
        hairCutPercentage: {
            maxLength: {
                value: 3,
                message: ' should be less than 3 Digit.',
            },
            allowed: ['dot'],
        },
        yearsInUAE: {
            maxLength: {
                value: 2,
                message: ' should be less than 2 Digit.',
            },
        },
        estimatedPropertyValue: {
            maxLength: {
                value: 15,
                message: ' Value should be less than 15 Digit.',
            },
            allowed: ['dot'],
            notAllowed: ['startWithZero'],
        },
        requestedLoanAmount: {
            maxLength: {
                value: 15,
                message: ' should be less than 15 Digit.',
            },
            allowed: ['dot'],
            notAllowed: ['startWithZero'],
        },
        requestedTenor: {
            maxLength: {
                value: 3,
                message: '',
            },
            notAllowed: ['startWithZero'],
        },
        interestOrProfit: {
            maxLength: {
                value: 6,
                message: '',
            },
            allowed: ['dot'],
            notAllowed: ['startWithZero'],
        },
        LReducedLimit: {
            maxLength: {
                value: 12,
                message: '',
            },
            allowed: ['dot'],
            notAllowed: ['startWithZero'],
        },
        IBANNumber: {
            pattern: {
                value: /^[Aa][Ee][0-9]{2}[a-zA-Z0-9]{3}[0-9]{16}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/i,
                message: ' is Invalid',
            },
            maxLength: {
                value: 23,
                message: ' should be less than 23 Characters.',
            },
            notAllowed: ['space'],
            validate: {
                isMashreq: (value:any) =>
                    String(value).substring(4, 7) !== '033' ||
                    " is shouldn't be Mashreq Bank",
            },
        },
        passportExpiryDate: {
            validate: {
                isExpired: (value:any) =>
                    Date.parse(value) -
                        Date.parse(new Date().toLocaleString()) >
                        0 || ' is Expired',
            },
        },
        visaExpiryDate: {
            validate: {
                isExpired: (value:any) =>
                    value === undefined || value === null || value === ''
                        ? true
                        : Date.parse(value) -
                              Date.parse(new Date().toLocaleString()) >
                              0 || ' is Expired',
            },
        },
        amiriDecree: {
            pattern: {
                value: /^[Aa,Dd]{2}[^*]{5,30}$/i,
                message: ' must start with AD',
            },
            maxLength: {
                value: 30,
                message: ' should be less than 30 Characters.',
            },
            minLength: {
                value: 5,
                message: ' should be min 5 Characters.',
            },
        },
        marsoomLetter: {
            pattern: {
                value: /^[Mm,Ll]{2}[^*]{5,30}$/i,
                message: ' must start with ML',
            },
            maxLength: {
                value: 30,
                message: ' should be less than 30 Characters.',
            },
            minLength: {
                value: 5,
                message: ' should be min 5 Characters.',
            },
        },
        chamberCertification: {
            pattern: {
                value: /^[Cc,Cc]{2}[^*]{5,30}$/i,
                message: ' must start with CC',
            },
            maxLength: {
                value: 30,
                message: ' should be less than 30 Characters.',
            },
            minLength: {
                value: 5,
                message: ' should be min 5 Characters.',
            },
        },
        tradeLicense: {
            pattern: {
                value: /^[Tt,Ll]{2}[^*]{5,20}$/i,
                message: ' must start with TL',
            },
            maxLength: {
                value: 20,
                message: ' should be less than 20 Characters.',
            },
            minLength: {
                value: 5,
                message: ' should be min 5 Characters.',
            },
        },
        federalTax: {
            pattern: {
                value: /^[0-9]*$/i,
                message: ' is Invalid',
            },
            maxLength: {
                value: 15,
                message: ' should be 15 Digit',
            },
            minLength: {
                value: 15,
                message: ' should be 15 Digit',
            },
        },
        finalROI: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        eiborBasedRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        marginRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        fixedRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        floorRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        lifeInsuranceRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
            decimal: 3,
        },
        applicableInterestRate: {
            maxLength: {
                value: 6,
                message: ' Value should be less than 6 Digit.',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        interestProfitRate: {
            maxLength: {
                value: 6,
                message: ' Value should be less than 6 Digit.',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        providerApplicationNumber: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['special_Characters'],
        },
        conversionRate: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            allowed: ['dot'],
        },
        earlySettlementFees: {
            maxLength: {
                value: 10,
                message: ' Value should be less than 10 Digit.',
            },
            max: {
                value: 1,
                message: ' value must be 1 or less',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        driversLic: {
            maxLength: {
                value: 20,
                message: ' Value should be less than 20 Digit.',
            },
            allowed: ['slash'],
        },
        partnershipDetailsShare: {
            maxLength: {
                value: 5,
                message: 'value must be between 0 and 99.99!',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        nameOnCard: {
            maxLength: {
                value: 20,
                message: 'max 20 characters',
            },
            pattern: {
                value: /^[a-zA-Z ]{0,100}$/i,
                message: ' is Invalid',
            },
            notAllowed: ['numbers'],
        },
        basicSalary: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        stlOrScSalary: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        declaredIncome: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        cityValidate: {
            maxLength: {
                value: 50,
                message: ' max 50 characters',
            },
        },
        addressValidate: {
            maxLength: {
                value: 100,
                message: ' max 100 characters',
            },
        },

        fixedAllowances: {
            maxLength: {
                value: 18,
                message: ' should be less than 18 digit',
            },
            allowed: ['dot'],
            decimal: 3,
        },
        commonAllowancesCredit: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        commonAllowancesDebit: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        lumpsumHRA: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        spouseSalary: {
            maxLength: {
                value: 20,
                message: ' should be less than 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        SalaryActualDebits: {
            maxLength: {
                value: 20,
                message: 'max 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        variableSalartAsPerPaySlip: {
            maxLength: {
                value: 20,
                message: 'max 20 digit',
            },
            allowed: ['dot'],
            decimal: 2,
        },
        lienFreezeAmount: {
            allowed: ['dot'],
            decimal: 2,
        },
    }
    return rules[fieldName]
}
