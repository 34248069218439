import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from 'mashreqdigital_materialui'

export interface ConfirmDialogProps {
  children: any,
  title: any,
  open: boolean,
  setOpen: any,
  onConfirm: any,
  type?: any
}

const ConfirmDialog : React.FC<ConfirmDialogProps> = ({ title, children, open, setOpen, onConfirm, type }): JSX.Element => {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog">
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          { type === undefined || type === "Confirm" ? (
            <>
              <Button
                variant="contained"
                onClick={() => setOpen(false)}
                color="default">
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  onConfirm();
                }}
                color="secondary">
                Yes
              </Button>
            </>
          ): null}
          { type === "Alert" && (
            <Button
              variant="contained"
              onClick={() => {
                onConfirm();
              }}
              color="secondary">
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
}
export default ConfirmDialog;
