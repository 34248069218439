import React, { memo } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import { TablePagination, Tooltip, Box } from 'mashreqdigitalui'

interface GridTableProps {
    rows: any[]
    columns: any[]
    isLoading?: boolean
    title: string
    actions?: any
    components?: any
    options?: any
    onSelectionChange?: any
    elevation?: boolean
    height?: any
    originalNoRecord?: boolean
    footerAction?: any
    tableClasses?: string
    onRowClick?: any
}
const GridTable: React.FC<GridTableProps> = ({
    columns,
    rows,
    isLoading,
    title,
    actions,
    components,
    options,
    onSelectionChange,
    elevation,
    height,
    footerAction,
    tableClasses,
    onRowClick,
}): JSX.Element => {
    let columnsList: any[] = []
    for (let row of columns) {
        if (row.short) {
            row.render = (rowData:any) => (
                <Tooltip
                    title={rowData[row.field]}
                    placement="right"
                    arrow={true}
                >
                    <div className="tooltip-inner-wrapper">
                        <span>
                            {rowData[row.field] !== null &&
                            rowData[row.field].length > 15
                                ? rowData[row.field].substring(0, 15) + '...'
                                : rowData[row.field]}
                        </span>
                    </div>
                </Tooltip>
            )
        }
        columnsList.push(row)
    }

    return (
        <div
            className={`dataTable ${
                !elevation && 'boxShadowNoneTable'
            } ${tableClasses}`}
        >
            <MaterialTable
                title={title}
                columns={columnsList}
                components={{
                    Toolbar: (props) => (
                        <div className="title">
                            <MTableToolbar {...props} />
                        </div>
                    ),
                    Pagination: (props) => (
                        <>
                            <Box className="pagiBox">
                                {footerAction.map(
                                    (buttonRows:any, index:any) => buttonRows.render
                                )}
                            </Box>
                            <TablePagination
                                {...props}
                                rowsPerPageOptions={[]}
                                rowsPerPage={7}
                            />
                        </>
                    ),
                    ...components,
                }}
                data={rows}
                isLoading={isLoading}
                options={{
                    headerStyle: {
                        backgroundColor: '#eaeaea',
                        color: '#000',
                        fontSize: 12,
                        position: 'sticky',
                        top: 0,
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                    },
                    rowStyle: {
                        fontSize: 12,
                    },
                    paging: true,
                    pageSize: 1000,
                    emptyRowsWhenPaging: false,
                    padding: 'dense',
                    minBodyHeight: height === null ? 400 : height,
                    maxBodyHeight: height === null ? 400 : height,
                    searchFieldVariant: 'outlined',
                    ...options,
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: 'Showing {count} Records',
                    },
                }}
                actions={actions}
                onSelectionChange={onSelectionChange}
                onRowClick={onRowClick}
            />
        </div>
    )
}
GridTable.defaultProps = {
    actions: [],
    components: {},
    options: {},
    onSelectionChange: null,
    elevation: true,
    isLoading: false,
    height: null,
    footerAction: [],
    tableClasses: '',
    onRowClick: null,
}
export default memo(GridTable)
