import animationData from '../lib/assets/lottie/logoAnimation.json'
import {
    MAIP_Channel,
    MAIP_Source,
    MAIP_SourcingChannelID,
    CCPLODAIP_SourcingChannelID,
    MORTGAGE_LOAN_ENT_MOD_KEY,
    MORTGAGE_LOAN_AIP_KEY,
    MORTGAGE_LOAN_SEARCH_APP_KEY,
    USER_MANAGEMENT_ENT_MOD_KEY,
    USER_MANAGEMENT_USER_KEY,
    USER_MANAGEMENT_ROLE_KEY,
    USER_MANAGEMENT_TEAM_KEY,
    TASK_MANAGEMENT_ENT_MOD_KEY,
    TASK_MANAGEMENT_MY_TASK_KEY,
    MORTGAGE_LOAN_NEW_APP_KEY,
    CCPLOD_ENT_MOD_KEY,
    SUPP_CARD_ENT_KEY,
    SUPP_CARD_ENT_MOD_KEY,
    CC_LIMIT_INC_ENT_KEY,
    CC_LIMIT_INC_ENT_MOD_KEY,
    CCPLOD_CCLIMIT_KEY,
    PRODUCT_NAMES_CODES,
    CCPLOD_AIP_KEYW,
    CCPLOD_NEW_APP_KEYW,
} from './constants'
import { GLOBAL_ERROR_KEYS, GLOBAL_ERROR_MESSAGE } from './errors'
import * as RoutePath from '../routes/config'
import { toast } from 'react-toastify'
import * as appContextAction from '../lib/context/actions/appContextActions'

export const showError = (errors: any, fieldName: string) => {
    return errors && errors[fieldName] && errors[fieldName]['message']
        ? errors[fieldName]['message']
        : ''
}

export const checkPageAccess = (
    entitlements:any,
    moduleKey: string,
    key: string) => {
    if (entitlements !== null) {
        if (entitlements.hasOwnProperty(moduleKey)) {
            return entitlements.USER_MANAGEMENT.includes(key)
        } else {
            return false
        }
    } else {
        return false
    }
}

export const lottieDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

export const filterExistRoleUG = (data:any, listData:any, key:any) => {
    if (data.length > 0) {
        for (let row of listData) {
            let uid = row[key]
            const index = data.findIndex((x:any) => x[key] === uid)
            if (index !== undefined) data.splice(index, 1)
        }
        return data
    } else {
        return []
    }
}

export const getAllKeysRoleUG = (listData:any, selectedKeys:any, key:any) => {
    var oldKeys: any[] = []
    for (let row of listData) {
        if (row[key] !== null) {
            oldKeys.push(row[key])
        }
    }
    var newKeys: any[] = []
    for (let row of selectedKeys) {
        if (row[key] !== null) {
            newKeys.push(row[key])
        }
    }
    return oldKeys.concat(newKeys)
}
export const getDateTimeToSingleWord = (date:any) => {
    if (date !== null) {
        let onlyDate = date.getDate()
        let stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        let onlyMonth = date.getMonth() + 1
        let stringOnlyMonth = onlyMonth.toString()
        if (onlyMonth <= 9) {
            stringOnlyMonth = '0' + onlyMonth.toString()
        }
        let onlyYear = date.getFullYear().toString()
        let fullDateString =
            stringOnlyDate + '' + stringOnlyMonth + '' + onlyYear
        return fullDateString
    } else {
        return ''
    }
}
export const getDateObjToDateString = (date:any) => {
    if (date !== null) {
        let onlyDate = date.getDate()
        let stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        let onlyMonth = date.getMonth() + 1
        let stringOnlyMonth = onlyMonth.toString()
        if (onlyMonth <= 9) {
            stringOnlyMonth = '0' + onlyMonth.toString()
        }
        let onlyYear = date.getFullYear().toString()
        let fullDateString =
            onlyYear + '/' + stringOnlyMonth + '/' + stringOnlyDate
        if (fullDateString === '10000/01/01') {
            return ''
        } else {
            return fullDateString
        }
    } else {
        return ''
    }
}
export const getDateObjToDateNewString = (date:any) => {
    if (date !== null) {
        let onlyDate = date.getDate()
        let stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        let onlyMonth = date.getMonth() + 1
        let stringOnlyMonth = onlyMonth.toString()
        if (onlyMonth <= 9) {
            stringOnlyMonth = '0' + onlyMonth.toString()
        }
        let onlyYear = date.getFullYear().toString()
        let fullDateString =
            onlyYear + '/' + stringOnlyMonth + '/' + stringOnlyDate
        if (fullDateString === '10000/01/01') {
            return null
        } else {
            return fullDateString
        }
    } else {
        return null
    }
}
export const getDateObjToDateStringISO = (date:any) => {
    if (date !== null) {
        var onlyDate = date.getDate()
        var stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        var onlyMonth = date.getMonth() + 1
        var stringOnlyMonth = onlyMonth.toString()
        if (onlyMonth <= 9) {
            stringOnlyMonth = '0' + onlyMonth.toString()
        }
        var onlyYear = date.getFullYear().toString()
        return onlyYear + '-' + stringOnlyMonth + '-' + stringOnlyDate
    } else {
        return null
    }
}
// export const isDateDueLater = (date:any) => {
//     const dDate = new Date(date)
//     const todayObj = new Date()
//     const todayDate = todayObj.getDate()
//     const todayDay = todayObj.getDay()

//     // get first date of week
//     const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay))

//     // get last date of week
//     const lastDayOfWeek = new Date(firstDayOfWeek)
//     lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)

//     // if date is equal or within the first and last dates of the week
//     return dDate >= lastDayOfWeek
// }

export const isDateToday = (date:any) => {
    const dDate = new Date(date)
    const todayObj = new Date()

    // if date is equal or within the first and last dates of the week
    return dDate === todayObj
}
export const isDateDueLater = (date:any) => {
    const dDate = new Date(date)
    const todayObj = new Date()
    const todayDate = todayObj.getDate()
    const lastDayOfWeek = new Date(todayObj.setDate(todayDate + 7))
    // if date is equal or within the first and last dates of the week
    return dDate > lastDayOfWeek
}
// export const isDateInThisWeek = (date:any) => {
//     const dDate = new Date(date)
//     const todayObj = new Date()
//     const todayDate = todayObj.getDate()
//     const todayDay = todayObj.getDay()

//     // get first date of week
//     const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay))

//     // get last date of week
//     const lastDayOfWeek = new Date(firstDayOfWeek)
//     lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)

//     // if date is equal or within the first and last dates of the week
//     return dDate >= firstDayOfWeek && dDate <= lastDayOfWeek
// }

export const isDateInThisWeek = (date:any) => {
    const dDate = new Date(date)
    const todayObj = new Date()
    const todayDateObj = new Date()
    const todayDate = todayObj.getDate()
    const lastDayOfWeek = new Date(todayObj.setDate(todayDate + 7))
    return dDate > todayDateObj && dDate <= lastDayOfWeek
}
// export const isDateOverdue = (date:any) => {
//     const dDate = new Date(date)
//     const todayObj = new Date()
//     const todayDate = todayObj.getDate()
//     const todayDay = todayObj.getDay()

//     // get first date of week
//     const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay))
//     // get over due date
//     const overdue = new Date(firstDayOfWeek)
//     overdue.setDate(overdue.getDate() - 6)

//     // if date is equal or within the first and last dates of the week
//     return  dDate <= overdue
// }

export const isDateOverdue = (date:any) => {
    const dDate = new Date(date)
    const todayObj = new Date()
    const todayDate = todayObj.getDate()
    const tillyesterdays = new Date(todayObj.setDate(todayDate - 1))
    return dDate <= tillyesterdays
}
export const getDateTimeForDisp = (date:any) => {
    if (date !== null) {
        var onlyDate = date.getDate()
        var stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        var onlyMonth = date.getMonth()
        var onlyYear = date.getFullYear().toString()
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ]
        return (
            stringOnlyDate +
            '-' +
            monthNames[onlyMonth] +
            '-' +
            onlyYear +
            ' ' +
            date.toLocaleTimeString()
        )
    } else {
        return ''
    }
}
export const getDateForDisp = (date:any) => {
    if (date !== null) {
        var onlyDate = date.getDate()
        var stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        var onlyMonth = date.getMonth()
        var onlyYear = date.getFullYear().toString()
        const monthNames = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
        ]
        return stringOnlyDate + '-' + monthNames[onlyMonth] + '-' + onlyYear
    } else {
        return ''
    }
}
export const getDateForDispFormat2 = (date:any) => {
    // Aug 15 2021 12:55PM
    var fullDate = date.split(' ', 3)
    if (fullDate.length > 1) {
        return fullDate[1] + '/' + fullDate[0] + '/' + fullDate[2]
    } else {
        return ''
    }
}
export const getSelectedLOVObject = (data:any, id:any) => {
    var returnData = {}
    if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            if (id !== null) {
                if (data[i]['id'] === parseInt(id)) {
                    returnData = data[i]
                    break
                }
            }
        }
    }
    return returnData
}
export const getSelectedLOVObjectNonInt = (data:any, id:any) => {
    var returnData = {}
    if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            if (id !== null) {
                if (data[i]['id'] === id) {
                    returnData = data[i]
                    break
                }
            }
        }
    }
    return returnData
}
export const getSelectedLOVObjectByDescription = (data:any, description:any) => {
    var returnData = {}
    if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            if (description !== '') {
                if (data[i]['description'] === description) {
                    returnData = data[i]
                    break
                }
            }
        }
    }
    return returnData
}
export const getSelectedLOVObjectByLabel = (data:any, label:any) => {
    var returnData = {}
    if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            if (label !== '') {
                if (data[i]['label'] === label) {
                    returnData = data[i]
                    break
                }
            }
        }
    }
    return returnData
}

export const getSelectedLovObjectbyValue = (data:any, value:any) => {
    let returnObject = data.find((obj:any) => obj.value === value)
    if (returnObject !== undefined) return returnObject
    else return {}
}
export const getSelectedLovObjectbyCode = (data:any, value:any) => {
    let returnObject = data.find((obj:any) => obj.code === value)
    if (returnObject !== undefined) return returnObject
    else return {}
}

export const getSelectedLOVObjectByFlexCode = (data:any, code:any) => {
    var returnData = {}
    if (data !== undefined) {
        for (var i = 0; i < data.length; i++) {
            if (code !== '') {
                if (data[i]['flexCode'] === code) {
                    returnData = data[i]
                }
            }
        }
    }
    return returnData
}
export const getDateToDateObject = (date:any) => {
    if (date !== null) {
        return new Date(date)
    } else {
        return null
    }
}
export const dateFormat = (type:any, date:any) => {
    if (type === 'DDMMYYY') {
        var onlyDate = date.getDate()
        var stringOnlyDate = onlyDate.toString()
        if (onlyDate <= 9) {
            stringOnlyDate = '0' + onlyDate.toString()
        }
        var onlyMonth = date.getMonth() + 1
        var stringOnlyMonth = onlyMonth.toString()
        if (onlyMonth <= 9) {
            stringOnlyMonth = '0' + onlyMonth.toString()
        }
        var onlyYear = date.getFullYear().toString()
        return stringOnlyDate + stringOnlyMonth + onlyYear
    }
}
export const getIDtoLabel = (teams:any, id:any) => {
    let groupName = ''
    for (let rows of teams) {
        if (id === rows['groupId']) {
            groupName = rows['name']
            break
        }
    }
    return groupName
}

export const monthDiff = (SD:any, ED:any) => {
    let months = 0
    if (SD !== null && ED !== null) {
        months =
            ED.getMonth() -
            SD.getMonth() +
            12 * (ED.getFullYear() - SD.getFullYear())
        if (ED.getDate() < SD.getDate()) {
            months--
        }
    }
    return String(months)
}

export const dateDiff = (SD:any, ED:any) => {
    var diffInTime = ED.getTime() - SD.getTime()
    var diffDays = diffInTime / (1000 * 3600 * 24)
    return diffDays
}
export const getErrorMessage = (errorCode:any) => {
    let errorMessage = ''
    if (errorCode in GLOBAL_ERROR_KEYS) {
        errorMessage = GLOBAL_ERROR_MESSAGE[errorCode]
    } else {
        if (errorCode !== undefined && errorCode !== null) {
            errorMessage = GLOBAL_ERROR_MESSAGE[errorCode] + ' : ' + errorCode
        } else {
            errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR
        }
    }

    if (errorCode !== undefined && errorCode !== null) {
        if (errorCode in GLOBAL_ERROR_KEYS) {
            errorMessage = GLOBAL_ERROR_MESSAGE[errorCode]
        } else {
            errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR + ' : ' + errorCode
        }
    } else {
        errorMessage = GLOBAL_ERROR_MESSAGE.COMMON_ERROR + ' : ' + errorCode
    }

    return errorMessage
}

export const getEntitlementValueWithPath = () => {
    let pathName = window.location.pathname
    if (pathName === '/rcms' + RoutePath.MORTGAGE_AIP) {
        return {
            MODULE: MORTGAGE_LOAN_ENT_MOD_KEY,
            ACTION: MORTGAGE_LOAN_AIP_KEY,
        }
    }
    if (
        pathName === '/rcms' + RoutePath.MORTGAGE_NEW ||
        pathName === '/rcms' + RoutePath.MORTGAGE_SEARCH
    ) {
        return {
            MODULE: MORTGAGE_LOAN_ENT_MOD_KEY,
            ACTION: MORTGAGE_LOAN_NEW_APP_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.MORTGAGE_SEARCH) {
        return {
            MODULE: MORTGAGE_LOAN_ENT_MOD_KEY,
            ACTION: MORTGAGE_LOAN_SEARCH_APP_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.CORE_USER) {
        return {
            MODULE: USER_MANAGEMENT_ENT_MOD_KEY,
            ACTION: USER_MANAGEMENT_USER_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.CORE_TEAM) {
        return {
            MODULE: USER_MANAGEMENT_ENT_MOD_KEY,
            ACTION: USER_MANAGEMENT_ROLE_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.CORE_ROLE) {
        return {
            MODULE: USER_MANAGEMENT_ENT_MOD_KEY,
            ACTION: USER_MANAGEMENT_TEAM_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.MY_TASKLIST) {
        return {
            MODULE: TASK_MANAGEMENT_ENT_MOD_KEY,
            ACTION: TASK_MANAGEMENT_MY_TASK_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.DASHBOARD) {
        return {
            MODULE: TASK_MANAGEMENT_ENT_MOD_KEY,
            ACTION: TASK_MANAGEMENT_MY_TASK_KEY,
        }
    }
    if (
        pathName === '/rcms' + RoutePath.SUPPLEMENTARY_CARD ||
        pathName === '/rcms' + RoutePath.SEARCH_SUPPLEMENTARY_CARD
    ) {
        return {
            MODULE: SUPP_CARD_ENT_MOD_KEY,
            ACTION: SUPP_CARD_ENT_KEY,
        }
    }
    if (
        pathName === '/rcms' + RoutePath.CREDIT_CARD_LIMIT ||
        pathName === '/rcms' + RoutePath.CREDIT_CARD_LIMIT_SEARCH
    ) {
        return {
            MODULE: CC_LIMIT_INC_ENT_MOD_KEY,
            ACTION: CC_LIMIT_INC_ENT_KEY,
        }
    }
    if (pathName === '/rcms' + RoutePath.APPROVE_IN_PRINCIPLE) {
        return {
            MODULE: CCPLOD_ENT_MOD_KEY,
            ACTION: CCPLOD_AIP_KEYW,
        }
    }
    if (
        pathName === '/rcms' + RoutePath.NEW_APPLICATION ||
        pathName === '/rcms' + RoutePath.SEARCH_APPLICATION
    ) {
        return {
            MODULE: CCPLOD_ENT_MOD_KEY,
            ACTION: CCPLOD_NEW_APP_KEYW,
        }
    }
    return {
        MODULE: '',
        ACTION: '',
    }
}
export const getGlobalAllApplicationData = (contextData:any) => {
    let currentApplicationContext =
        contextData !== null && contextData.globalApplicationData !== undefined
            ? contextData.globalApplicationData
            : {}
    return Object.keys(currentApplicationContext).length > 0
        ? currentApplicationContext
        : {}
}
export const getSelectedApplicationContextIndex = (
    allApplication:any,
    applicationId
:any) => {
    let index = -1
    for (let i = 0; i < allApplication.length; i++) {
        if (allApplication[i].applicationId === applicationId) {
            index = i
        }
    }
    return index
}
export const getDaysDiff = (d1:any, d2:any): any => {
    let days = 0
    try {
        days = (d1 - d2) / (1000 * 60 * 60 * 24)
    } catch (e) {
        days = 0
    }
    return parseInt(days.toString()).toString()
}
export const getApplicationStatus = (rowData:any) => {
    let completedStatus = ''
    try {
        if (rowData.active.toLowerCase() === 'y') {
            if (rowData.applicationAIPStatus !== null) {
                if (rowData.applicationAIPStatus === 'reject') {
                    completedStatus = 'Closed'
                } else {
                    completedStatus = 'Open'
                }
            } else {
                if (completedStatus === '') {
                    completedStatus = 'Open'
                } else {
                    completedStatus = rowData.completedStatus
                }
            }
        }
        if (rowData.active.toLowerCase() === 'n') {
            completedStatus = 'Closed'
        }
    } catch (e) {
        console.log(e)
    }

    return completedStatus
}
export const getModuleName = (key:any) => {
    if (key === MORTGAGE_LOAN_ENT_MOD_KEY) {
        return 'Mortgage Loan'
    }
    if (key === CCPLOD_ENT_MOD_KEY) {
        return 'CCPLOD'
    }
    if (key === SUPP_CARD_ENT_MOD_KEY) {
        return 'Supplementary Card'
    }
    if (key === CC_LIMIT_INC_ENT_MOD_KEY) {
        return 'Credit Card Limit'
    }
    return ''
}
export const getSubModuleName = (key:any) => {
    if (key === MORTGAGE_LOAN_AIP_KEY) {
        return 'AIP'
    }
    if (key === MORTGAGE_LOAN_NEW_APP_KEY) {
        return 'New'
    }
    if (key === CCPLOD_CCLIMIT_KEY) {
        return 'New'
    }
    return ''
}

export const showToast = (status:any, message:any) => {
    toast.dismiss()
    if (status === 'success') {
        toast.success(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
    }
    if (status === 'error') {
        toast.error(message, {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            toastId: 'errorToast',
        })
    }
    if (status === 'warning') {
        toast.warn(message, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
    }
    if (status === 'info') {
        toast.info(message, {
            position: 'top-center',
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
    }
}
export const objLength = (obj:any) => {
    if (obj !== undefined && obj !== null) return Object.keys(obj).length
    else return 0
}
export const setAppStorage = (
    context:any,
    moduleName:any,
    tabs:any,
    applicationId:any,
    data
:any) => {
    let allApplication = getGlobalAllApplicationData(context[0])
    let singleApp:any = {}
    if (allApplication[applicationId] === undefined) {
        singleApp[applicationId] = {
            [moduleName]: {
                [tabs]: {
                    data,
                },
            },
        }
    } else {
        singleApp[applicationId] = {
            [moduleName]: {
                [tabs]: {
                    data,
                },
                ...allApplication[applicationId][moduleName],
            },
        }
    }
    allApplication[applicationId] = singleApp[applicationId]
    context[1](appContextAction.addApplicationDataGlobal(allApplication))
}

export const clearAppStorage = (context:any, applicationId:any) => {
    let allApplication = getGlobalAllApplicationData(context[0])
    allApplication[applicationId] = undefined
    context[1](appContextAction.addApplicationDataGlobal(allApplication))
}

export const getAppStorage = (context:any, moduleName:any, tabName:any, applicationId:any) => {
    let allApplication = getGlobalAllApplicationData(context[0])
    try {
        return allApplication[applicationId][moduleName][tabName] !== undefined
            ? allApplication[applicationId][moduleName][tabName]
            : null
    } catch (e) {
        return null
    }
}
export const loginFormatDate = (dateVal:any) => {
    var newDate = new Date(dateVal)

    var sMonth = padValue(newDate.getMonth() + 1)
    var sDay = padValue(newDate.getDate())
    var sYear = newDate.getFullYear()
    var sHour = newDate.getHours()
    var sMinute = padValue(newDate.getMinutes())
    var sAMPM = 'AM'

    var iHourCheck = sHour

    if (iHourCheck > 12) {
        sAMPM = 'PM'
        sHour = iHourCheck - 12
    } else if (iHourCheck === 0) {
        sHour = 12
    }

    sHour = padValue(sHour)

    return (
        sDay +
        '-' +
        sMonth +
        '-' +
        sYear +
        ' ' +
        sHour +
        ':' +
        sMinute +
        ' ' +
        sAMPM
    )
}
const padValue = (value:any) => {
    return value < 10 ? '0' + value : value
}
