import { memo } from 'react'
import { Route,BrowserRouter as Router,Routes } from 'react-router-dom'
// import { COUNTRY, DIRECTION, MODE, SEGMENT, USER_TYPE } from '@mashreq/types'
import { CssBaseline,  ThemeProvider } from 'mashreqdigital_materialui'
import { createBrowserHistory } from 'history'
import GlobalCss from './GlobalCss'
import App from '../App'
import Appointment from '../pages/postlogin/Appointment'
import Billing from '../pages/postlogin/Billing'
import AppErrorBoundry from './AppErrorBoundry'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { GlobalSentryBoundary } from './GlobalSentryBoundary'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from './ErrorComponent'

const history = createBrowserHistory()
const Setup = () => {
    function ErrorHandler({ error,resetErrorBoundary }: { error: any; resetErrorBoundary: any }) {
        // function ErrorHandler({ error, resetErrorBoundary }) {
        return (
            <ErrorComponent
                error={error}
                resetErrorBoundary={resetErrorBoundary}
            />
        )
    }

    return (
        // <ThemeProvider
        //     theme={''}
        // >
        <>           <GlobalCss />
             {/* <Router> */}
                <Routes>
                {/* <AppErrorBoundry> */}
                    {/* <CssBaseline />*/}
                    {/* <ErrorBoundary FallbackComponent={ErrorHandler}>  */}
                        <Route 
                            path="/"
                            element={ <App />}
                            // element={ <Login />}
                        />
                         <Route 
                        path="/appointment"
                        element={ <Appointment />}
                        // element={ <Login />}
                    />
                    <Route 
                   path="/billing"
                   element={ <Billing />}
                   // element={ <Login />}
               />
                    {/* </ErrorBoundary> */}
                {/* </AppErrorBoundry> */}
             </Routes>
            {/* </Router> */}
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ width: '500px' }}
            /></>
 
        // </ThemeProvider>
    )
}

export default memo(Setup)
