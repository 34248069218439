export const ROOT = '/'
export const LOGIN = '/'
export const APPOINTMENT = '/appointment'
export const DASHBOARD = '/dashboard'
export const BILLING = '/billing'

export const CORE_USER = '/management/user'
export const CORE_TEAM = '/management/team'
export const CORE_ROLE = '/management/role'

export const MY_TASKLIST = '/management/tasklist/my'
export const REASSIGN_TASKLIST = '/management/tasklist/reassign'

export const APPROVE_IN_PRINCIPLE = '/ccplod/approveprinciple'
export const NEW_APPLICATION = '/ccplod/new'
export const SEARCH_APPLICATION = '/ccplod/searchapplication'

export const CREDIT_CARD_LIMIT = '/creditcardlimit/new'
export const CREDIT_CARD_LIMIT_SEARCH = '/creditcardlimit/search'

export const SUPPLEMENTARY_CARD = '/supplementarycard/new'
export const EMPLOYER_FRAUD_CHECK = '/fraud/employerfraudcheck'
export const CUSTOMER_FRAUD_CHECK = '/fraud/customerfraudcheck'
export const SEARCH_SUPPLEMENTARY_CARD = '/supplementarycard/search'
export const CAMPAIGN_MANAGEMENT = '/campaign/CampaignManagement'
export const BULK_TERMINATE_MANAGEMENT = '/application/BulkTerminationManagement'
export const FILE_UPLOAD = '/admin/FileUpload'

export const AECB_STATUS_UPDATE = '/liability/aecbstatus'

export const INITIATE_STATEMENT = '/statement/initial'
export const SEARCH_STATEMENT = '/statement/search'

export const MORTGAGE_AIP = '/mortgage/aip'
export const MORTGAGE_SEARCH = '/mortgage/search'
export const MORTGAGE_NEW = '/mortgage/new'

export const CUSTOMER_MANAGEMENT = '/management/customer'

export const CORE_ICONS = '/core/icons'
