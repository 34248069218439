import { useEffect, useState } from 'react'
import {
    UnderlineText,
    Grid,
    Paper,
    Button,
    Box,
    Modal,
} from 'mashreqdigitalui'
import { useForm } from 'react-hook-form'

// import * as RoutePath from 'routes/config'
import CustomField from '../../components/customfield'

import * as Endpoints from '../../lib/networks/endpoints'
import SideBarWrapper from '../../common/layouts/SideBarWrapper'
import { useFetchAPI } from '../../lib/networks/useFetchAPI'
import GridTable  from '../../lib/components/gridtable'
import {
    SYSTEM_PAGE_TITLE,
} from '../../util/constants'
const Billing = () => {

    useEffect(() => {
        document.title = SYSTEM_PAGE_TITLE.BILLING
},[])
    const { control } = useForm({
        mode: 'onTouched',
      })
    let billingColumn: any[] = [
        {
            field: 'registrationId',
            title: 'Registration Id',
        },
        {
            field: 'accountCode',
            title: 'Account Code',
        },
        {
            field: 'billNo',
            title: 'Bill No',
        },
        {
            field: 'billHeads',
            title: 'Bill Heads',
        },
        {
            field: 'description',
            title: 'Description',
        },
        {
            field: 'quantity',
            title: 'Quantity',
        },
        {
            field: 'amount',
            title: 'Amount',
        },
        {
            field: 'icdCode',
            title: 'Icd Code',
        },
        {
            field: 'coInsAmt',
            title: 'Co Insurance Amt',
        },
        {
            field: 'insAmt',
            title: 'Insurance Amt',
        },
        {
            field: 'grossAmt',
            title: 'Gross Amt',
        },
        {
            field: 'denCode',
            title: 'Den Code',
        },
        {
            field: 'activity',
            title: 'Activity',
        },
        {
            field: 'rcode',
            title: 'RCode',
        },
        {
            field: 'insPaid',
            title: 'Insurance Paid',
        },
        {
            field: 'patientAmt',
            title: 'Patient Amount',
        },
        {
            field: 'discAmt',
            title: 'Discount Amt',
        },
        {
            field: 'netAmt',
            title: 'Net Amt',
        },
    ]
    return (
        <SideBarWrapper>
            <>
            <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ paddingRight: 10 }}
            >
                <GridTable
                    title={''}
                    rows={[]}
                    columns={billingColumn}
                    elevation={false}
                    height={150}
                    footerAction={[
                        {
                            render: (
                                <Button
                                    className="smallButton iconButton pt10"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{
                                        marginLeft: '5px',
                                        marginTop: '1px',
                                    }}
                                    // disabled={!isEditable}
                                    // onClick={() =>
                                    //     actionProduct('AddRow', null)
                                    // }
                                >
                                    +
                                </Button>
                            ),
                        },
                        {
                            render: (
                                <>
                                    {/* {isEditable && (
                                        <>
                                            <span
                                                style={{
                                                    verticalAlign: 'sub',
                                                    paddingLeft: 5,
                                                    color: '#797979',
                                                }}
                                            >
                                                Some of products and campaign
                                                are disabled
                                            </span>
                                            <Button
                                                className="smallButton iconButton pt10"
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                style={{
                                                    marginLeft: '5px',
                                                    marginTop: '1px',
                                                }}
                                                disabled={!isEditable}
                                                onClick={() =>
                                                    setOpenEligiblityPopup(true)
                                                }
                                            >
                                                ?
                                            </Button>
                                        </>
                                    )} */}
                                </>
                            ),
                        },
                    ]}
                    options={{
                        search: false,
                        toolbar: false,
                        rowStyle: (rowData:any) => ({
                            // backgroundColor:
                            //     selectedProductListId !== null &&
                            //     rowData.tableData.id ===
                            //         selectedProductListId.tableData.id
                            //         ? '#ffaa78'
                            //         : '#fff',
                        }),
                    }}
                    onRowClick={(e:any, rowData:any) => {
                        // if (
                        //     rowData.productCampaign.code === 'NTU1' ||
                        //     rowData.productCampaign.code === '9999'
                        // ) {
                        //     if (
                        //         applicationDetails.newToUAE === undefined ||
                        //         applicationDetails.newToUAE === null ||
                        //         !applicationDetails.newToUAE
                        //     ) {
                        //         setAlertConfirm({
                        //             Open: true,
                        //             Text: 'Campaign "New to UAE" is only available for customers that are New to UAE. Please mark the "New to UAE" checkbox on Personal Details tab.',
                        //             Action: 'ALERT',
                        //             Type: 'Alert',
                        //             Data: null,
                        //         })
                        //         return
                        //     }
                        // }

                        // if (
                        //     rowData.mode === 'SAVED' &&
                        //     objLength(rowData.productType) > 0 &&
                        //     getNotAvailableValues('PRODUCT_TYPE', false).some(
                        //         (product) =>
                        //             product === rowData.productType.description
                        //     )
                        // ) {
                        //     setAlertConfirm({
                        //         Open: true,
                        //         Text:
                        //             rowData.productType.description +
                        //             ' Product Cannot be added to the Customer.',
                        //         Action: 'ALERT',
                        //         Type: 'Alert',
                        //         Data: null,
                        //     })
                        //     return
                        // }

                        // if (
                        //     rowData.mode === 'SAVED' &&
                        //     objLength(rowData.productName) > 0 &&
                        //     !getSelectedProductDetails(
                        //         rowData.productType.id,
                        //         'PRODUCT_NAME',
                        //         productNameList,
                        //         getSelectedLOVObject(
                        //             nationalityLov,
                        //             applicationDetails.nationalityId
                        //         ),
                        //         [],
                        //         null
                        //     ).some(
                        //         (product) =>
                        //             product.description ===
                        //             rowData.productName.description
                        //     )
                        // ) {
                        //     setAlertConfirm({
                        //         Open: true,
                        //         Text:
                        //             rowData.productName.description +
                        //             ' Product Cannot be added to the Customer.',
                        //         Action: 'ALERT',
                        //         Type: 'Alert',
                        //         Data: null,
                        //     })
                        //     return
                        // }
                        // if (
                        //     rowData.mode === 'SAVED' &&
                        //     !externalLiabilityFlag &&
                        //     rowData.productAppType['code'] === 'Buy Out'
                        // ) {
                        //     showToast(
                        //         'error',
                        //         'You dont have any external liabilties to select buy out!'
                        //     )
                        //     return
                        // }
                        // if (
                        //     rowData.mode === 'SAVED' &&
                        //     objLength(rowData.productAppType) > 0 &&
                        //     !getSelectedProductDetails(
                        //         rowData.productName.id,
                        //         'PRODUCT_APP_TYPE',
                        //         productAppTypeList,
                        //         null,
                        //         null,
                        //         null,
                        //         liabilitiesData,
                        //         rowData,
                        //         applicationDetails.branchCode
                        //     ).some(
                        //         (product) =>
                        //             product.code === rowData.productAppType.code
                        //     )
                        // ) {
                        //     setAlertConfirm({
                        //         Open: true,
                        //         Text: ' Selected Product Application Type is not allowed for the selected product type.',
                        //         Action: 'ALERT',
                        //         Type: 'Alert',
                        //         Data: null,
                        //     })
                        //     return
                        // }

                        // if (
                        //     rowData.mode === 'SAVED' &&
                        //     objLength(rowData.productCampaign) > 0 &&
                        //     !getSelectedProductDetails(
                        //         rowData.productName.id,
                        //         'PRODUCT_CAMPAIGNS',
                        //         productCampaignList,
                        //         null,
                        //         null,
                        //         applicationDetails,
                        //         [],
                        //         rowData,
                        //         '',
                        //         masterLOVs
                        //     ).some(
                        //         (product) =>
                        //             product.description ===
                        //             rowData.productCampaign.description
                        //     )
                        // ) {
                        //     setAlertConfirm({
                        //         Open: true,
                        //         Text: ' Selected Product Campaign is not allowed for the selected product.',
                        //         Action: 'ALERT',
                        //         Type: 'Alert',
                        //         Data: null,
                        //     })
                        //     return
                        // }

                        // if (rowData.mode === 'SAVED') {
                        //     if (
                        //         selectedProductListId !== null &&
                        //         rowData.tableData.id ===
                        //             selectedProductListId.tableData.id
                        //     ) {
                        //         setSelectedProductListId(null)
                        //     } else {
                        //         setSelectedProductListId(rowData)
                        //     }
                        // }
                    }}
                    //isLoading={
                        // getProductDetailsDataLoading ||
                        // setProductDetailsInsertDataLoading ||
                        // setProductDetailsDeleteDataLoading
                    //}
                />
                </Grid>
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid container xs={6} sm={6} md={6} lg={6} xl={6}>
                    
                <Box
                                className="paperBoxSubHead"
                                style={{ marginTop: 5 }}
                            >
                                <UnderlineText>
                                    <span>BILL TOTAL</span>
                                </UnderlineText>
                            </Box>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Grid container style={{ marginBottom: '1rem' }}>
              <CustomField
                type={'text'}
                name={'total'}
                label={'Total'}
                control={control}
                grid={4}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'coInsurance'}
                label={'Co-Insurance'}
                control={control}
                grid={4}
                //disabled={true}
              />

              
              <CustomField
                type={'text'}
                name={'deductable'}
                label={'Deductable'}
                control={control}
                grid={2}
                //disabled={true}
              />

                <CustomField
                type={'text'}
                name={'deductableAmt'}
                label={'...'}
                control={control}
                grid={2}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'drug'}
                label={'Drug'}
                control={control}
                grid={4}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'dental'}
                label={'Dental'}
                control={control}
                grid={2}
                //disabled={true}
              />
              <CustomField
                type={'text'}
                name={'dentalAmt'}
                label={'...'}
                control={control}
                grid={2}
                //disabled={true}
              />
              <CustomField
                type={'text'}
                name={'hcpcs'}
                label={'HCPcs'}
                control={control}
                grid={4}
                //disabled={true}
              />

              {/* <CustomField
                type={'autocomplete'}
                name={'sellerId'}
                ukey={'sellerId'}
                label={'Seller ID'}
                options={sellerIDList}
                optionKey={'label'}
                control={control}
                grid={4}
                disabled={
                  !isEditable ||
                  index !== 0 ||
                  Object.keys(applicationDetails).length > 0
                }
                onInputChange={(e) => {
                  setValue('sellerName', e.id)
                  onValueChange('sellerId', e)
                }}
                ruleList={['required']}
              /> */}

            </Grid>
          {/* </form> */}
          </Grid>
          <Grid container xs={6} sm={6} md={6} lg={6} xl={6}>
                    
                    <Box
                                    className="paperBoxSubHead"
                                    style={{ marginTop: 5 }}
                                >
                                    <UnderlineText>
                                        <span>INSURANCE</span>
                                    </UnderlineText>
                                </Box>
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                <Grid container style={{ marginBottom: '1rem' }}>
                  <CustomField
                    type={'text'}
                    name={'totalamt'}
                    label={'Total Amt'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
    
                  <CustomField
                    type={'text'}
                    name={'prior'}
                    label={'Prior'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
    
                  
                  <CustomField
                    type={'text'}
                    name={'amountPaid'}
                    label={'Amount Paid'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
    
    
                  <CustomField
                    type={'text'}
                    name={'policyno'}
                    label={'Policy No.'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
    
                  <CustomField
                    type={'text'}
                    name={'outstanding'}
                    label={'Out Standing Balance'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
                  <CustomField
                    type={'text'}
                    name={'asopCertificateNo'}
                    label={'Asop/Cert No.'}
                    control={control}
                    grid={4}
                    //disabled={true}
                  />
    
    
                </Grid>
              {/* </form> */}
               </Grid>
          </Grid>

          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
                                className="paperBoxSubHead"
                                style={{ marginTop: 5 }}
                            >
                                <UnderlineText>
                                    <span>PATIENT</span>
                                </UnderlineText>
                            </Box>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Grid container style={{ marginBottom: '1rem' }}>
              <CustomField
                type={'text'}
                name={'patientTotal'}
                label={'Patient Total'}
                control={control}
                grid={2}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'discount'}
                label={'Discount'}
                control={control}
                grid={2}
                //disabled={true}
              />

              
              <CustomField
                type={'text'}
                name={'amountPaid'}
                label={'Amount Paid'}
                control={control}
                grid={2}
                //disabled={true}
              />


              <CustomField
                type={'text'}
                name={'amountPay'}
                label={'Amount Pay'}
                control={control}
                grid={2}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'cardpaid'}
                label={'Card Paid'}
                control={control}
                grid={2}
                //disabled={true}
              />
              <CustomField
                type={'text'}
                name={'cardpay'}
                label={'Card Pay'}
                control={control}
                grid={2}
                //disabled={true}
              />
                <CustomField
                type={'text'}
                name={'chequePaid'}
                ukey={'customerName'}
                label={'chequePaid'}
                control={control}
                grid={2}
                //disabled={true}
              />

              <CustomField
                type={'text'}
                name={'chequePay'}
                label={'Cheque Pay'}
                control={control}
                grid={2}
                //disabled={true}
              />

              
              <CustomField
                type={'text'}
                name={'payableamt'}
                label={'Payable Amount'}
                control={control}
                grid={2}
                //disabled={true}
              />
              
              <CustomField
                type={'text'}
                name={'balance'}
                label={'Balance'}
                control={control}
                grid={2}
                //disabled={true}
              />

            </Grid>
              </Grid>
              
              <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    // style={{ display: 'none' }}
                >
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            New
                        </Button>
                    }
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            Save
                        </Button>
                    }
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            Edit
                        </Button>
                    }
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            Reset
                        </Button>
                    }
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            Delete
                        </Button>
                    }
                    {
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="small"
                            id="formButton"
                            // onClick={(e) => summarySave()}
                        >
                            Print
                        </Button>
                    }
                </Grid>
            </>
        </SideBarWrapper>
        )
}

export default Billing