import {  memo } from 'react'
import { useRoutes } from 'react-router-dom'
import Login from '../pages/prelogin/Login'
import Appointment from '../pages/postlogin/Appointment'
import Billing from '../pages/postlogin/Billing'

// const Appointment = lazy(() => import('../pages/postlogin/Appointment'))
// const LogIn = lazy(() => import('../pages/prelogin/Login'))

const Routers = () => {
    const routes = useRoutes([
        { path: '/', element: <Login /> },
        { path: '/appointment', element: <Appointment /> },
        { path: '/billing', element: <Billing /> },
    ]);
  
    return routes;
    // return (
    //     <>
    //         <Suspense
    //             fallback={
    //                 <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
    //                     <Box
    //                         className="centerBoxInAppSearch"
    //                         style={{ marginLeft: 0 }}
    //                     >
    //                         <Lottie options={lottieDefaultOptions} />
    //                     </Box>
    //                 </Grid>
    //             }
    //         >
                
    //             {/* <Router> */}
    //            <Routes>
    //                 <Route path={RoutePath.ROOT} element={<Login />} />
    //                 {/* <Route
    //                     key="LOGIN"
    //                     path={RoutePath.LOGIN}
    //                     element={<Login />}
    //                 /> */}
    //                 <Route
    //                     // key="Appointment"
    //                     path={RoutePath.APPOINTMENT}
    //                     element={<Appointment />}
    //                 />
    //                 {/* <Route path="*" element={<Appointment/>} /> */}
    //                 {/* <ProtectedRoute
    //                     // key="Appointment"
    //                     path={RoutePath.DASHBOARD}
    //                     element={<Appointment/>}
    //                 /> */}
    //                 {/* <Redirect exact from="*" to={RoutePath.LOGIN} /> */}
    //             </Routes>
    //             {/* </Router> */}
    //         </Suspense>
    //         {/* {navigate(RoutePath.LOGIN)} */}
    //     </>
    // )
}

export default memo(Routers)
