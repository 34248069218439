import {
    Avatar,
    Box,
    Grid,
    ListItemText,
    Menu,
    MenuItem,
    ListItem,
} from 'mashreqdigitalui'
import React, { useState } from 'react'
import packageJson from '../../../package.json'
import { StateContext } from '../../lib/context/context'
import { useNavigate } from 'react-router-dom'
import * as RouteConfig from '../../routes/config'
import ConfirmDialog from '../../components/confirmdialog'

const MOLHeader = (props:any) => {
    const contextData = React.useContext(StateContext)
    const [alertConfirm, setAlertConfirm] = useState({ open: false })

    const { profileVisible, versionDisplay } = props
    const history = useNavigate()
    let authUser =
        contextData !== null &&
        contextData.authuser !== undefined &&
        Object.keys(contextData.authuser).length > 0
            ? contextData.authuser
            : {}

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Grid container justifyContent='flex-end' className="headerDesign">
                {versionDisplay && (
                    <Box style={{ color: '#ff5e00', padding: 5 }}>
                        Current Version: <strong>{packageJson.version}</strong>
                    </Box>
                )}
                {profileVisible && (
                    <Box>
                        <Box onClick={handleClick}>
                            {authUser['fullName']}
                            <Avatar
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    backgroundColor: '#ff5e00',
                                    display: 'inline-grid',
                                    marginLeft: '10px',
                                }}
                            >
                                {/* {authUser['fullName'].charAt(0)} */}
                            </Avatar>
                        </Box>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            className="menuBottom0"
                        >
                            <>
                                {Boolean(anchorEl) && (
                                    <>
                                        <div
                                            style={{ padding: 10, width: 300 }}
                                        >
                                            <Grid
                                                container
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    item
                                                    xs={2}
                                                    sm={2}
                                                    md={2}
                                                    lg={2}
                                                    xl={2}
                                                >
                                                    <Avatar
                                                        style={{
                                                            width: '35px',
                                                            height: '35px',
                                                            backgroundColor:
                                                                '#ff5e00',
                                                            display:
                                                                'inline-grid',
                                                            marginLeft: '10px',
                                                            marginTop: 5,
                                                        }}
                                                    >
                                                        {/* {authUser[
                                                            'fullName'
                                                        ].charAt(0)} */}
                                                    </Avatar>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={10}
                                                    sm={10}
                                                    md={10}
                                                    lg={10}
                                                    xl={10}
                                                    style={{ paddingLeft: 5 }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 18,
                                                            display: 'block',
                                                            color: '#ff5e00',
                                                        }}
                                                    >
                                                        {authUser['fullName']}
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        {authUser['email']}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    style={{
                                                        marginTop: 15,
                                                        paddingLeft: 15,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        Your Phone Number
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {authUser['phone']}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    style={{
                                                        marginTop: 5,
                                                        paddingLeft: 15,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        Your Staff ID
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {authUser['staffId']}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    style={{
                                                        marginTop: 5,
                                                        paddingLeft: 15,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        Your Branch
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {authUser[
                                                            'branchId'
                                                        ] === null
                                                            ? 'NA'
                                                            : authUser[
                                                                  'branchId'
                                                              ]}
                                                    </span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    style={{
                                                        marginTop: 5,
                                                        paddingLeft: 15,
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#646464',
                                                        }}
                                                    >
                                                        Your Channel
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            display: 'block',
                                                            color: '#000',
                                                        }}
                                                    >
                                                        {authUser['channelId']}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <MenuItem
                                            onClick={() => {
                                                handleClose()
                                                setAlertConfirm({ open: true })
                                            }}
                                            style={{
                                                padding: 0,
                                                background: '#ff5e00',
                                            }}
                                        >
                                            <ListItem>
                                                <ListItemText
                                                    primary="Logout"
                                                    className="whiteColorSpan"
                                                />
                                            </ListItem>
                                        </MenuItem>
                                    </>
                                )}
                            </>
                        </Menu>
                    </Box>
                )}
                <ConfirmDialog
                    title=""
                    open={alertConfirm.open}
                    setOpen={() => setAlertConfirm({ open: false })}
                    onConfirm={() => {
                        setAlertConfirm({ open: false })
                        history(RouteConfig.LOGIN)
                    }}
                >
                    Are you sure you want to logout?
                </ConfirmDialog>
            </Grid>
        </>
    )
}
MOLHeader.defaultProps = {
    versionDisplay: false,
    profileVisible: false,
}
export default MOLHeader
