import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import { Box, Tooltip } from 'mashreqdigitalui'
// import { ArrowDownIcon, ArrowUpIcon } from 'components/icons'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Id: PropTypes.number,
  route: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
  isHideShowMenu: PropTypes.bool,
  history: PropTypes.object,
  actionReload: PropTypes.func,
}

type AppMenuItemPropType = PropTypes.InferProps<typeof AppMenuItemPropTypes>
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropType, 'items'>

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[]
}

const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const location: any = useLocation()
  const classes = useStyles()
  const {
    name,
    Icon,
    Id,
    link,
    items = [],
    isHideShowMenu,
    route,
    actionReload,
  } = props

  const [showListItem, setShowListItem] = React.useState(
    false)

  const history = useNavigate()
  const [openId, setOpenId] = useState(location.state ? location.state.Id : '' || isHideShowMenu !== null ? !isHideShowMenu : false)

  const isExpandable = items && items.length > 0

  const handleClick = (id:any) => {
    if (openId === id) {
      setOpenId('')
    } else {
      setOpenId(id)
    }
    if (link === undefined) {
    } else {
      if (window.location.pathname === link) {
        if (actionReload !== undefined && actionReload !== null) {
          actionReload()
        }
      } else {
        let routeId = 0

        if (route === 'appointment') routeId = 2
        else if (route === 'billing') routeId = 3
        else if (route === 'ccplod') routeId = 4
        else if (route === 'mortgage') routeId = 5
        else if (route === 'statement') routeId = 6
        else if (route === 'liability') routeId = 7
        else if (route === 'supplementarycard') routeId = 8
        else if (route === 'fraud') routeId = 10
        else if (route === 'creditcardlimit') routeId = 11
        toast.dismiss("errorToast")

        // history(link !== null ? link : '/login',
        history(link !== null ? link : '/',
        {state: {
          Id: routeId,
        }})
      }
    }
  }
  const onOverMouse = () => {
    setShowListItem(true)
  }
  const onOutMouse = () => {
    setShowListItem(false)
  }
  const MenuItemRoot = (
    <ListItem
      button
      key={Id}
      onClick={() => handleClick(Id)}
      style={isHideShowMenu ? {} : { display: 'inline-block' }}
      className={classes.menuItem}
    >
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={isHideShowMenu ? name : !Icon ? name : ''}
        inset={!Icon}
        className={!Icon ? classes.menuItemText : ''}
      />
      {isHideShowMenu ? (
        <>
          {isExpandable && openId !== Id && 
          <ArrowBackIcon />
          
          }
          {
          isExpandable && openId === Id &&
          <ArrowForwardIcon />
      }
        </>
      ) : (
          ''
        )}
    </ListItem>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={openId === Id}
      timeout="auto"
      unmountOnExit
      className={
        !isHideShowMenu
          ? showListItem
            ? classes.displayOnMouse
            : classes.displayOnMouseOff
          : ''
      }
    >
      <Divider />

      <List component="div" disablePadding key={Id}>
        {items.map((item, index) => (
          <AppMenuItem
            {...item}
            key={index}
            actionReload={actionReload}
          />
        ))}
      </List>
    </Collapse>
  ) : null
  return (
    <>
      <Box onMouseEnter={onOverMouse} onMouseLeave={onOutMouse}>
        {Icon ? (
          !isHideShowMenu ? (
            <Tooltip title={name} placement="right" arrow={true}>
              {MenuItemRoot}
            </Tooltip>
          ) : (
              MenuItemRoot
            )
        ) : (
            MenuItemRoot
          )}
        {MenuItemChildren}
      </Box>
    </>
  )
}

AppMenuItem.propTypes = AppMenuItemPropTypes

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      '&& .MuiTypography-root': {
        fontSize: '1rem',
      },
    },
    menuItemIcon: {
      color: '#97c05c',
      minWidth: '35px',
      fontSize: '1.2rem',
    },
    menuItemText: {
      paddingLeft: '35px',
    },
    displayOnMouse: {
      position: 'absolute',
      left: '48px',
      background: 'white',
      boxShadow: '5px 8px 15px #dddddd4a',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'inline-block',
    },
    displayOnMouseOff: {
      position: 'fixed',
      left: '48px',
      background: 'white',
      boxShadow: '5px 8px 15px #dddddd4a',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      display: 'none',
    },
  })
)
export default AppMenuItem
