import React from 'react'
import List from '@material-ui/core/List'
import AppMenuItem from './AppMenuItem'
import { makeStyles, createStyles } from 'mashreqdigitalui'
import MashreqLogo from '../../assets/images/logo.png'
import MashreqIcon from '../../assets/images/logo.png'

interface AppMenuProps {
    isHideShowMenu: boolean
    appMenuItems: any[]
    actionReload?: Function
}
const AppMenu: React.FC<AppMenuProps> = ({
    isHideShowMenu,
    appMenuItems,
    actionReload,
}) => {
    const classes = useStyles()
    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            {isHideShowMenu ? (
                <img
                    src={MashreqLogo}
                    className="sidemenulogo"
                    alt="mashreqLogo"
                />
            ) : (
                <img
                    src={MashreqIcon}
                    className="sidemenuIcon"
                    alt="mashreqLogo"
                />
            )}

            {appMenuItems.map((item, index) => (
                <AppMenuItem
                    {...item}
                    key={index}
                    isHideShowMenu={isHideShowMenu}
                    actionReload={actionReload}
                />
            ))}
        </List>
    )
}

const drawerWidth = 250

const useStyles = makeStyles((theme) =>
    createStyles({
        appMenu: {
            position: 'initial',
            width: '100%',
            '&& .MuiTypography-root': {
                fontSize: '1rem',
            },
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
        },
        menuItemIcon: {
            color: '#97c05c',
            minWidth: '35px',
        },
    })
)

export default AppMenu
