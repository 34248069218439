export const API_STATUS_SUCCESS = 'success'
export const API_STATUS_ERROR = 'error'
export const LOGIN_ERROR_MESSAGE = 'Something went wrong, Try Again'
export const SUCCESS_UPDATE_DATA_MESSAGE = 'Record Updated Successfully!'
export const SUCCESS_INSERT_DATA_MESSAGE = 'Record Inserted Successfully!'
export const SUCCESS_DELETE_DATA_MESSAGE = 'Record Deleted Successfully!'
export const REQUIRED_PRODUCT_DETAILS_VALUE =
    'Please provide all fields value for product details!'
export const REQUIRED_ADDRESS_DETAILS_VALUE = 'Please provide address details!'

export const REQUIRED_PAYSLIP_DETAILS_VALUE =
    'Please provide all fields value for Salary as per B/S or PaySlip Details!'

export const REQUIRED_PRODUCT_DETAILS =
    'Please provide at least 1 product detail!'
export const LOADING_DATA_MESSAGE = 'Please Wait....!'
export const ERROR_UPDATE_DATA_MESSAGE = 'Something went wrong, Try Again'
export const LOGOUT_MESSAGE = 'Last logged in session on '
export const ACTIVE = 'ACTIVE'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const INFO_MESSAGE_PASSPORT_CONFLICT = ' is used in this Application.'

export const MESSAGE_BANK_MAND_DATA =
    'Please provide Bank in Final DSR Liabilities!'

export const MESSAGE_REJECT =
    'Are you sure you want to Reject this Application?'
export const MESSAGE_REJECT_ALERT =
    'Your application has been Rejected successfully!'
export const MESSAGE_ACCEPT_AIP =
    'Your application has been submitted successfully. Please coordinate with your branch for further processing of the credit application.'
export const MESSAGE_MAIL_SENT = 'Mail has been sent successfully!'
export const MESSAGE_SUBMIT_CIC = 'Application Successfully Submitted to CIC!'
export const MESSAGE_SUBMIT_CCPLOD =
    'Application Successfully Submitted to CCPLOD!'
export const MESSAGE_PRI_EMIRATES_MATCH =
    ' should not be same as Secondary Emirates ID!'
export const MESSAGE_SEC_EMIRATES_MATCH =
    ' should not be same as Primary Emirates ID!'
export const MESSAGE_CURRENT_PASSPORT_MATCH =
    ' should not be same as old passport'
export const MESSAGE_OLD_PASSPORT_MATCH =
    ' should not be same as current passport'
export const MESSAGE_PASSPORT_MATCH_ERROR =
    'Current Passport and Old Passport Number cant be same'

export const ERRORCODES = [
    {
        errorCode: 'RCMS-PROXY-ERROR-1001',
        message:
            'Something went wrong. Contact system administrator if issue persists.',
        showSupport: true,
    },
    {
        errorCode: 'RCMS-PROXY-ERROR-1002',
        message:
            'Display message “Your login session expired. Please login again with your credentials to continue.',
        showSupport: false,
    },
    {
        errorCode: 'RCMS-PROXY-ERROR-1003',
        message: 'Invalid Login Username or Password!',
        showSupport: false,
    },
    {
        errorCode: 'RCMS-CORE-ERROR-1004',
        message:
            'Something went wrong. Contact system administrator if issue persists',
        showSupport: true,
    },
    {
        errorCode: 'RCMS-LDAP-AUTH-ERROR-1005',
        message: 'Invalid Login Username or Password!',
        showSupport: false,
    },
    {
        errorCode: 'RCMS-AUTH-ERROR-1006',
        message:
            'Something went wrong. Contact system administrator if issue persists.',
        showSupport: true,
    },
    {
        errorCode: 'RCMS-AUTH-ERROR-1007',
        message:
            'You don’t have permission to access the RCMS application. Raise an iHelp service request to get access to the required module in RCMS.',
        showSupport: false,
    },
    {
        errorCode: 'RCMS-AUTH-ERROR-1008',
        message:
            'Something went wrong. Contact system administrator if issue persists.',
        showSupport: true,
    },
    {
        errorCode: 'RCMS-5000',
        message:
            'Something went wrong. Contact system administrator if issue persists.',
        showSupport: true,
    },
]
export const ALLOWED_SPECIAL_CHARACTERS = ['@', '-', '_', '.']
export const NOT_ALLOWED_NUMBER = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
]
export const NOT_ALLOWED_SPECIAL_CHARACTERS = [
    '+',
    '-',
    '@',
    '&',
    '|',
    '!',
    '(',
    ')',
    '{',
    '}',
    '[',
    ']',
    '^',
    '~',
    '*',
    '?',
    ':',
    '"',
    '\\',
    '/',
    '#',
    '$',
    '%',
    ';',
    "'",
    '_',
    '.',
    '<',
    '>',
    '=',
]
/* Layout Styles */
const style = {
    header: 88, //minHeight
    footer: 56, //minHeight
}
export const globalStyle: any = {
    ...style,
    defaultGutter: 10, //padding - left and right
    sidebarWidth: 50, //width,
    postLogin: {
        height: `calc(100vh - ${style.header + style.footer + 2}px)`, // added 2 because of  header & footer border
        top: style.header + 1, // added 1 because of  header border
    },
    logo: {
        height: 26,
        width: 60,
    },
}
export const USER_MANAGEMENT_ENT_MOD_KEY = 'UserManagement'
export const USER_MANAGEMENT_USER_KEY = 'user'
export const USER_MANAGEMENT_ROLE_KEY = 'roles'
export const USER_MANAGEMENT_TEAM_KEY = 'teams'

export const TASK_MANAGEMENT_ENT_MOD_KEY = 'TaskManagement'
export const TASK_MANAGEMENT_MY_TASK_KEY = 'my_tasklist'
export const TASK_MANAGEMENT_RE_ASSIGN_TASK_KEY = 'myteam_tasklist'

export const MORTGAGE_LOAN_ENT_MOD_KEY = 'MortgageLoan'
export const MORTGAGE_LOAN_AIP_KEY = 'approval_in_principle'
export const MORTGAGE_LOAN_NEW_APP_KEY = 'new_application'
export const MORTGAGE_LOAN_SEARCH_APP_KEY = 'search_application'

export const ACCOUNT_STATEMENT_ENT_MOD_KEY = 'AccountStatement'
export const ACCOUNT_STATEMENT_INITIATE_KEY = 'initiate_statement'
export const ACCOUNT_STATEMENT_SEARCH_KEY = 'search_statements'

export const LIABILITY_REPORT_ENT_MOD_KEY = 'LiabilityReport'
export const LIABILITY_REPORT_AECB_KEY = 'aecb_status_update'

export const CCPLOD_ENT_MOD_KEY = 'CCPLOD'
export const CCPLOD_SEARCH_KEY = 'ccplod_search_application'
export const CCPLOD_NEW_APP_KEY = 'ccplod_new_application'
export const CCPLOD_AIP_KEY = 'ccplod_approval_in_principle'
export const CCPLOD_AIP_KEYW = 'approval_in_principle'
export const CCPLOD_NEW_APP_KEYW = 'new_application'
export const APPOINTMENT = 'appointment'
export const BILLING = 'billing'
export const CCPLOD_SEARCH_KEYW = 'search_application'

export const CCPLOD_CCLIMIT_KEY = 'credit_card_limit_increase'

export const SUPP_CARD_ENT_MOD_KEY = 'SupplementaryCard'
export const SUPP_CARD_ENT_KEY = 'supplementary_card'

export const CC_LIMIT_INC_ENT_MOD_KEY = 'CreditLimitIncrease'
export const CC_LIMIT_INC_ENT_KEY = 'credit_card_limit_increase'

export const MAIP_Source = 'AIP'
export const CLI_Source = 'CLI'
export const CLI_SourcingChannelID = 'CLI'
export const CSUPP_Source = 'SUPP'
export const MAIP_SourcingChannelID = 'Mortgage'
export const MCIC_SourcingChannelID = 'Mortgage-CIC'
export const CCPLODAIP_SourcingChannelID = 'CCPLOD'
export const CSUPP_SourcingChannelID = 'SUPP'
export const MAIP_Channel = 'RCMS'
export const LIABILITIES_INTERNAL = 'RCMS INTERNAL'

export const ZERO_TO_TEN_DROPDOWN = [
    { label: '0' },
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
    { label: '5' },
    { label: '6' },
    { label: '7' },
    { label: '8' },
    { label: '9' },
    { label: '10' },
]
export const AECB_STATUS_DROPDOWN = [
    { label: 'Disbursed' },
    { label: 'Not Taken Up' },
    { label: 'Rejected' },
    { label: 'Declined' },
]
export const AECB_UPDATE_STATUS_DROPDOWN = [
    { label: 'Disbursed' },
    { label: 'Not Taken Up' },
    { label: 'Rejected' },
    { label: 'Declined' },
]
export const PREFERENCES_LIST = [
    { label: 'Same as Residence' },
    { label: 'Same as Office' },
]
export const SALARY_LIST_DEFAULT = [
    {
        month: 1,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
    {
        month: 2,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
    {
        month: 3,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
    {
        month: 4,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
    {
        month: 5,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
    {
        month: 6,
        salaryCreditedDate: null,
        salaryCreditedDateError: false,
        actualSalaryCredits: '',
        salaryMode: '',
        actualSalaryCreditsError: false,
        salaryModeError: false,
    },
]
export const PRODUCT_NAMES_CODES = {
    PLEM: 'Al Hal (PLEM)',
    LPLEM: 'Legacy Loan (PLEM)',
    PLEX: 'Mashreq Personal (PLEX)',
}
export const SYSTEM_PAGE_TITLE = {
    ICON_LIST: ' CLINIC - Mashreq Icon List',
    DASHBOARD: ' CLINIC - Dashboard',
    STATEMENT_INITIATE: ' CLINIC - Account Statement | Initiate Statement',
    SEARCH_STATEMENT: ' CLINIC - Account Statement | Search Statement',
    TERMINATE_APPLICATION: ' CLINIC - Terminate Application',
    CCPLOD_AIP: ' CLINIC - Approval In Principle(AIP)',
    CCPLOD_NEW_APP: ' CLINIC - New Application',
    CCPLOD_SEARCH_APP: ' CLINIC - Search Application',
    CLI_SEARCH_APP: ' CLINIC - Credit Card Limit Increase',
    SUPP_NEW_APP: ' CLINIC - Supplementary Card',
    SUPP_SEARCH_APP: ' CLINIC - Search Supplementary Card',
    CUST_MANAGEMENT: ' CLINIC - Customer Management',
    FRAUD_CUSTOMER_CHECK: ' CLINIC - Employer Fraud Check',
    FRAUD_EMPLOYER_CHECK: ' CLINIC - Customer Fraud Check',
    AECB_STATUS_UPDATE: ' CLINIC - Liability Report | AECB Status Update',
    MORT_AIP: ' CLINIC - Mortgage | Approval In Principle(AIP)',
    MORT_NEW_APP: ' CLINIC - Mortgage | New Application',
    MORT_SEARCH_APP: ' CLINIC - Mortgage | Search Application',
    MY_TASK_LIST: ' CLINIC - My Task List',
    MY_TEAM_TASK_LIST: ' CLINIC - My Team Task List',
    ROLE_MANAGMENT: ' CLINIC - Role Management',
    USER_MANAGMENT: ' CLINIC - User Management',
    TEAM_MANAGMENT: ' CLINIC - Team Management',
    CAMPAIGN_MANAGMENT: ' CLINIC - Campaign Management',
    CHANGE_LOG: ' CLINIC - ChangeLog',
    LOGIN_SCREEN: ' CLINIC - Login',
    APPOINTMENT: ' CLINIC - Appointment',
    BILLING: ' CLINIC - Billing',
}

export const escalationResponse = {
    productType: 'PL',
    customerDetails: {
        segment: 'VisaCard-Unsecured;/Fresh;/',
        employmentStatus: 'Salaried Customer',
        applicationReference: '3598431',
        cif: '013519678',
        applicationId: '269617',
        timeStamp: '12/14/2021, 11:53:31 AM',
        customerName: 'Ivan James Prithishk Vettivel',
        nationality: 'U.A.E',
        age: 29.08,
        companyName:
            'MOHAMMED BIN RASHID UNIVERSITY OF MEDICINE AND HEALTH SCIENCES',
        companyCategory: 'Blue/1/Services - Education',
        maxUnsecuredExposureAED: 20000,
        interest: 5.99,
        requestedMultiple: 16.5,
        tenor: 48,
        reasonForFinance: 'need loan',
    },
    deviation: [
        {
            productType: 'Credit Card',
            deviationName: 'Name does not match',
            deviationCode: 'DM1031',
        },
        {
            productType: 'Credit Card',
            deviationName: 'Mobile number does not match',
            deviationCode: 'DM1032',
        },
        {
            productType: 'Common',
            deviationName:
                'Please check if the customer has an existing legacy loan on us or Off us',
            deviationCode: 'DM3057',
        },
    ],
    collateral: {
        securityChequeAmount: 0,
        insuranceCoverage: true,
    },
    onUsCardsBehaviour: {
        creditCardTotalLimitAED: 0,
        utilization: 0,
        delinquencyStringCard: 0,
        behaviorScore: 0,
        usageNature: '',
        cashUtilization: 0,
    },
    onUsLoanBehaviour: {
        loanAmountAED: 0,
        currentOutstandingAED: 0,
        delinquencyStringLoan: '',
        behaviourScore: 0,
        remainingTenor: 0,
    },
    offUsBureauBehavior: {
        bureauSegmentationIris: 'NO HIT',
        personalLoans: 'Principle  0   OS  0  Oldested Vintage  0 months',
        rod: 'Limit 0  OS  0   Oldested Vintage 0 months',
        creditCard: 'Limit 0   OS  0   Oldested Vintage  0  months',
        currentlyCurrent: true,
        highestDelinquencyStringBureau: 0,
        cardOverUtilization105: false,
        externalEnquiryBureauLast6Months:
            ' LoanValue 0  CardValue  0 Loan#  0  Card#  0',
        bounceCheque: 'Internal 0 External 0 Total 0',
        los: 35,
        lengthOfRelation: 0,
        familyUAE: 'No   Spouse Employed   No   Family Size',
        yearsUAE: 5,
        behMRSLoan: '',
        behMRSCard: '',
        appMRSLoan: '',
        appMRSCard: '',
    },
    capacity: {
        designation: 'OTHERS',
        income: 50000,
        dsr: 'Limit: 4 Utilization 4',
    },
    capital: {
        residentType: 'RENT - TENANT',
        privateBanking: 'Personnal banking',
        mmc: '',
        verifyAvailableBalance: 0,
    },
    currentAccountBalanceDetails: [
        {
            month: 'November 2021',
            lowBalance: 0,
            highBalance: 0,
            avgCreditBalance: 0,
            closingBalance: 0,
            accountNumber: '0191100612329',
            ccy: 'AED',
        },
        {
            month: 'December 2021',
            lowBalance: 0,
            highBalance: 0,
            avgCreditBalance: 0,
            closingBalance: 0,
            accountNumber: '0191100612329',
            ccy: 'AED',
        },
    ],
    salaryCreditTo: {
        salary: [
            {
                salaryCreditDate: 'month1',
                salary: 50000,
                remarks: '',
            },
            {
                salaryCreditDate: 'month2',
                salary: 50000,
                remarks: '',
            },
            {
                salaryCreditDate: 'month3',
                salary: 50000,
                remarks: '',
            },
        ],
        additionalObservation: '',
        fileComments:
            'L1 CE CA Decision > Approve  Reason >  Details: BV CE U Decision > Approve  Reason >  Details: L1 CE CA Decision > Approve  Reason >  Details: L1 CE EA Decision > Approve  Reason >  Details: okL1 CE EA Decision > Approve  Reason >  Details: okCPV CE U Decision > Approve  Reason >  Details: CPV CE U Decision > Approve  Reason >  Details: CPV CE U Decision > Approve  Reason >  Details: inconclusiveL2 CE EA Decision > Approve  Reason > DCO2 C01 Counter Offer  Details: okL1 CE RMD Decision > Approve  Reason >  Details: okL1 CE RMD  > E Name check waived off for the Goverment Employer MOHAMMED BIN RASHID UNIVERSITY OF MEDICINE AND HEALTH SCIENCES  by SYSTEML1 CE CA Decision > Escalate to L2  Reason > AL2 R337  Details: okCPV CE U Decision > Approve  Reason > CPVA R333_1  Details: BV CE U Decision > Approve  Reason >  Details: okL1 CE EA Decision > Escalate to L2  Reason > RDL2 R336_1  Details: okL1 CE EA Decision > Escalate to L2  Reason > RDL2 R336_1  Details: Eligibility Error: Adding to ',
        managerCIC: '',
        headOfCIC: '',
        headOfRetailCredit: '',
        headOfRiskManagement: '',
    },
}
