import React, { useContext, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import AppMenu from '../../components/sideDrawer/AppMenu'
import { Box } from 'mashreqdigitalui'
import * as appContextAction from '../../lib/context/actions/appContextActions'
import { DispatchContext, StateContext } from '../../lib/context/context'
import MOLHeader from '../../common/header'
import * as RoutePath from '../../routes/config'
// import {
    // LayoutDashboardIcon,
    // MultipleNeutral2Icon,
    // MonetizationApprIcon,
    // HouseUserIcon,
    // AccountingCalcIcon,
    // AccountingDocIcon,
    // CreditCardIcon,
    // InformationDeskCustomerIcon,
    // ArrowLeftIcon,
    // ArrowRightIcon,
    // TaskCheckListIcon,
// } from '../../components/icons'
// import {Icon} from './Icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosSharp';
import CalendarToday from '@material-ui/icons/CalendarToday';
import PictureAsPdf from '@material-ui/icons/Bookmark';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import {
    APPOINTMENT
} from '../../util/constants'
// import {
//     LayoutDashboardIcon,
//     MultipleNeutral2Icon,
//     MonetizationApprIcon,
//     HouseUserIcon,
//     AccountingCalcIcon,
//     AccountingDocIcon,
//     CreditCardIcon,
//     InformationDeskCustomerIcon,
//     ArrowLeftIcon,
//     ArrowRightIcon,
//     TaskCheckListIcon,
// } from '../../components/icons'

export interface SideBarWrapperProps {
    children: React.ReactElement
    defaultHide?: boolean
    actionReload?: Function
}

const SideBarWrapper: React.FC<SideBarWrapperProps> = (props) => {
    const classes = useStyles()
    const { children, defaultHide, actionReload } = props
    const appContextDispatch = useContext(DispatchContext)
    const contextData = useContext(StateContext)
    
    const [isHideShowMenu, setHideShowMenu] = useState(
        
        defaultHide !== undefined
            ? !defaultHide
            : contextData !== null &&
              contextData.sidemenu !== undefined &&
              Object.keys(contextData.sidemenu).length > 0
            ? contextData.sidemenu.open
            : true
    )
        
    const getSideMenuList = () => {
        var items: any[] = []
        var entitlements =
            contextData.authuser !== undefined
                ? contextData.authuser.entitlements
                : {}

        items.push({
            name: 'Appointment',
            link: RoutePath.APPOINTMENT,
            Icon:CalendarToday,
            Id: 1,
        })
        
        items.push({
            name: 'Billing',
            link: RoutePath.BILLING,
            Icon: PictureAsPdf,
            Id: 2,
        })
        // if (entitlements !== null) {
        //     if (entitlements.hasOwnProperty(USER_MANAGEMENT_ENT_MOD_KEY)) {
        //         // User Management
        //         items.push({
        //             name: 'User Management',
        //             Icon: null,
        //             items: [],
        //             Id: 2,
        //         })
        //         if (
        //             entitlements[USER_MANAGEMENT_ENT_MOD_KEY].includes(
        //                 USER_MANAGEMENT_USER_KEY
        //             )
        //         ) {
        //             items[items.length - 1].items.push({
        //                 name: 'Users',
        //                 link: RoutePath.CORE_USER,
        //                 route: 'management',
        //                 Id: 1,
        //             })
        //         }
        //         if (
        //             entitlements[USER_MANAGEMENT_ENT_MOD_KEY].includes(
        //                 USER_MANAGEMENT_ROLE_KEY
        //             )
        //         ) {
        //             items[items.length - 1].items.push({
        //                 name: 'Roles',
        //                 link: RoutePath.CORE_ROLE,
        //                 route: 'management',
        //                 Id: 2,
        //             })
        //         }
        //         if (
        //             entitlements[USER_MANAGEMENT_ENT_MOD_KEY].includes(
        //                 USER_MANAGEMENT_TEAM_KEY
        //             )
        //         ) {
        //             items[items.length - 1].items.push({
        //                 name: 'Teams',
        //                 link: RoutePath.CORE_TEAM,
        //                 route: 'management',
        //                 Id: 3,
        //             })
        //         }
        //     }
            
        // }
        return items
    }

    const toggleMenu = () => {
        setHideShowMenu(!isHideShowMenu)
        appContextDispatch(
            appContextAction.addSideMenu({
                open: !isHideShowMenu,
            })
        )
    }

    return (
        <div className={clsx('App', classes.root)}>
            <Box
                display="flex"
                height="100%"
                style={{ flex: 1, width: '100%' }}
            >
                <CssBaseline />
                <Box style={{ position: 'fixed', zIndex: 999 }}>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: isHideShowMenu
                                ? classes.drawerPaperSmall
                                : classes.drawerPaperBig,
                        }}
                    >
                        <AppMenu
                            isHideShowMenu={isHideShowMenu}
                            appMenuItems={getSideMenuList()}
                            actionReload={actionReload}
                        />
                        <Box
                            className={`sideShowHideIcon ${
                                isHideShowMenu ? 'ShowIconLeft' : 'HideIconLeft'
                            }`}
                            onClick={() => toggleMenu()}
                        >
                            {isHideShowMenu ? (
                                <ArrowBackIcon />

                            ) : (
                                <ArrowForwardIcon />
                            )
                            }
                        </Box>
                    </Drawer>
                </Box>
                <Box
                    style={{
                        width: isHideShowMenu
                            ? 'calc(100% - 280px)'
                            : 'calc(100% - 50px)',
                        marginLeft: isHideShowMenu ? '280px' : '50px',
                    }}
                >
                    <MOLHeader profileVisible={true} />
                    <Box style={{ padding: '15px' }}>{children}</Box>
                </Box>
            </Box>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#f9f9f9',
    },
    drawerPaperSmall: {
        position: 'relative',
        whiteSpace: 'nowrap',
        paddingBottom: theme.spacing(4),
        background: '#FFF',
        width: 280,
        transition: 'width 0.5s',
        height: '100vh',
    },
    drawerPaperBig: {
        position: 'initial',
        whiteSpace: 'nowrap',
        paddingBottom: theme.spacing(4),
        background: '#FFF',
        width: 50,
        overflow: 'hidden',
        transition: 'width 0.5s',
        height: '100vh',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
}))

export default SideBarWrapper
