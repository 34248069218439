import { Controller } from 'react-hook-form'
import {
    FormControlLabel,
    RadioGroup,
    Radio,
    Grid,
    TextField,
    makeStyles,
    createStyles,
    Theme,
    Switch,
    Checkbox,
    FormLabel,
    Box,
    Autocomplete,
    InputAdornment,
    CircularProgress,
    // CheckboxWithLabel,
    IconButton,
} from 'mashreqdigital_materialui'
import { validationRules } from '../../util/validations'
import DatePicker from 'react-datepicker'
import Popper from '@material-ui/core/Popper'
import {
    ALLOWED_SPECIAL_CHARACTERS,
    NOT_ALLOWED_NUMBER,
    NOT_ALLOWED_SPECIAL_CHARACTERS,
} from '../../util/constants'
import { useDebouncedCallback } from 'use-debounce'
import { useEffect, useState } from 'react'
// import { CalendarIcon, EyeIcon, EyeIconOff } from 'components/icons'
interface InputControllerProps {
    type: string
    name: string
    label: string
    control: any
    grid: any
    options: any
    optionKey: string
    inputValue: any
    onInputChange: any
    onTextChange?: any
    errorTrigger?: any
    onBlur: any
    isLoading: any
    disabled: boolean
    ruleList: any
    maxDate: any
    minDate: any
    ukey: any
    areaRow: any
    onInputBlur?: any
    remote?: boolean
    capital?: boolean
    onOpen?: any
    autoSize?: boolean
    sideLabel?: boolean
    disableClearable?: boolean
    width?: number
    freeText?: boolean
    disabledValue?: any
    absoluteWidth?: any
    tooltip?: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridField: (props: any) => ({
            padding: theme.spacing(1),
            '&& .react-datepicker-wrapper': {
                width: '100%',
            },
        }),
        gridFieldZero: (props: any) => ({
            padding: 0,
            '&& .react-datepicker-wrapper': {
                width: '100%',
            },
            width: props.width === 0 ? 'initial' : props.width,
        }),
        formLabel: {
            display: 'block',
            position: 'absolute',
            top: '-10px',
            background: 'white',
            fontSize: '11px',
            color: '#313131',
            padding: '0px 5px',
            left: '10px',
        },
        formDiv: {
            position: 'relative',
            minHeight: '35px',
        },
        checkBoxPlace: {
            position: 'absolute',
            top: '5px',
        },
        paper: {
            width: '250px',
        },
    })
)

const CustomField = (props: InputControllerProps) => {
    const classes = useStyles(props)
    const {
        capital,
        areaRow,
        ukey,
        maxDate,
        minDate,
        type,
        name,
        label,
        control,
        grid,
        options,
        optionKey,
        inputValue,
        onInputChange,
        onInputBlur,
        isLoading,
        disabled,
        ruleList,
        remote,
        onTextChange,
        errorTrigger,
        onOpen,
        sideLabel,
        disableClearable,
        freeText,
        disabledValue,
        absoluteWidth,
        tooltip,
    } = props

    const [fieldError, setFieldError] = useState({
        status: false,
        message: '',
    })
    useEffect(() => {
        if (errorTrigger) {
            setFieldErrorData(true)
        } else {
            setFieldErrorData(false)
        }
    }, [errorTrigger])

    const debounced = useDebouncedCallback((value) => {
        onTextChange(value)
    }, 1000)

    const [showPassword, setShowPassword] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event:any) => {
        event.preventDefault()
    }

    const onFieldValueChange = (onChange: any) => (_e:any, data?: any) => {
        onChange(data)
        if (onInputChange !== null) {
            onInputChange(data)
        }
    }
    const onFieldValueChangeFree = (onChange: any, e:any) => {
        if (e !== null) {
            let data = {
                id: e.target.value,
                [optionKey]: e.target.value,
            }
            onChange(data)
            if (onInputChange !== null) {
                onInputChange(data)
            }
        }
    }

    const numberValidation = (onChange: any, e:any) => {
        let value = e.target.value
        if (type === 'number') {
            try {
                // if (value !== null) {
                //     if (value.includes('.')) {
                //         if (
                //             value.substr(value.indexOf('.') + 1, value.length)
                //                 .length ===
                //             (rules['decimal'] !== undefined
                //                 ? rules['decimal'] + 1
                //                 : 3)
                //         ) {
                //         } else onChange(e)
                //     } else onChange(e)
                // }
            } catch (e) {
                console.log(e)
            }
        } else onChange(e)
    }

    const onTextValueChange = (onChange: any, type:any) => (e:any) => {
        numberValidation(onChange, e)
        if (onInputChange !== null) {
            numberValidation(onInputChange, e)
        }
    }

    const onTextValueBlur = (onBlur: any) => (e:any) => {
        onBlur(e)
        if (onInputBlur !== null) {
            onInputBlur(e)
        }
    }
    let setAdornment: object = {}
    if (isLoading) {
        setAdornment = {
            endAdornment: (
                <InputAdornment position="end">
                    <CircularProgress size={20} />
                </InputAdornment>
            ),
        }
    } else {
        if (type === 'password') {
            setAdornment = {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ?'':''
                            //  <EyeIconOff /> : <EyeIcon />
                             }
                        </IconButton>
                    </InputAdornment>
                ),
            }
        } else {
            setAdornment = {}
        }
    }
    let styles: object = {}
    if (type !== 'textarea') {
        styles = {
            style: {
                height: areaRow !== null && areaRow !== 1 ? 'inherit' : '35px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 5,
                padding: 5,
            },
        }
    } else {
        styles = {
            style: {
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 5,
                padding: 5,
                height: 'inherit',
            },
        }
    }
    let rules: any = {}

    if (ruleList.indexOf('required') > -1) {
        if (type === 'autocomplete') {
            rules = {
                validate: (value:any) =>
                    Object.keys(
                        value === undefined || value === null ? {} : value
                    ).length || ' Required',
                ...validationRules(ukey !== null ? ukey : name),
            }
        } else {
            rules = {
                required: ' Required',
                ...validationRules(ukey !== null ? ukey : name),
            }
        }
    } else {
        if (type === 'autocomplete') {
            rules = {
                validate: false,
                ...validationRules(ukey !== null ? ukey : name),
            }
        } else {
            rules = {
                required: false,
                ...validationRules(ukey !== null ? ukey : name),
            }
        }
    }
    if (rules === undefined) {
        rules = []
    }

    const PopperMy = function (props:any) {
        return (
            <Popper
                {...props}
                style={{ width: 'fit-content' }}
                placement="bottom-start"
            />
        )
    }

    const setFieldErrorData = (status:any) => {
        if (status) {
            if (!fieldError.status) {
                setFieldError({
                    status: true,
                    message: 'Required',
                })
            }
        }
        if (!status) {
            if (fieldError.status) {
                setFieldError({
                    status: false,
                    message: '',
                })
            }
        }
    }

    const getOptionLabelLogic = (optionData:any) => {
        let optionKeyData = optionKey.split(',')
        let optionKeyDataLength = optionKeyData.length
        let optionConcat = ''
        if (optionKeyDataLength === 1) {
            return optionData[optionKey] !== undefined
                ? optionData[optionKey]
                : ''
        }

        if (optionKeyDataLength > 1) {
            if (optionData[optionKeyData[0]] === undefined) return ''
            for (var i = 0; i < optionKeyDataLength; i++) {
                if (optionData[optionKeyData[i]] === null) continue
                optionConcat += optionData[optionKeyData[i]]
                if (optionKeyDataLength !== optionKeyDataLength - 1)
                    optionConcat += ' '
            }

            return optionConcat
        }
    }

    return (
        <Grid
            item
            xs={grid}
            sm={grid}
            md={grid}
            lg={grid}
            xl={grid}
            className={label === '' ? classes.gridFieldZero : classes.gridField}
        >
            {type === 'text' ||
            type === 'number' ||
            type === 'textarea' ||
            type === 'password' ? (
                control !== null ? (
                    <Controller
                        name={name}
                        control={control}
                        render={({
                            field: { onChange, value, onBlur },
                            fieldState: { error },
                        }) => (
                            <>
                                <TextField
                                    label={label}
                                    variant={'standard'}
                                    value={
                                        type === 'textarea'
                                            ? value === null
                                                ? ''
                                                : value
                                            : value
                                    }
                                    onChange={onTextValueChange(onChange, type)}
                                    error={!!error}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        required:
                                            ruleList.indexOf('required') > -1,
                                    }}
                                    InputProps={{
                                        ...styles,
                                        ...setAdornment,
                                        inputProps: {
                                            min: 0,
                                            step: 'any',
                                        },
                                    }}
                                    size="small"
                                    className={disabled ? 'disabledInput' : ''}
                                    multiline={type === 'textarea'}
                                    type={
                                        type !== 'text'
                                            ? type === 'password'
                                                ? showPassword
                                                    ? 'text'
                                                    : 'password'
                                                : type
                                            : 'text'
                                    }
                                    helperText={
                                        error
                                            ? error.message !== ''
                                                ? label + error.message
                                                : ''
                                            : null
                                    }
                                    onBlur={onTextValueBlur(onBlur)}
                                    disabled={disabled}
                                    minRows={
                                        type === 'textarea'
                                            ? areaRow !== null
                                                ? areaRow
                                                : 3
                                            : 1
                                    }
                                    onKeyDown={(e) => {
                                        if (type === 'number') {
                                            if (
                                                e.key === 'ArrowDown' ||
                                                e.key === 'ArrowUp'
                                            ) {
                                                e.preventDefault()
                                            }
                                        }
                                    }}
                                    onKeyPress={(e) => {
                                        if (type === 'number') {
                                            if (
                                                e.key === 'e' ||
                                                e.key === 'E'
                                            ) {
                                                e.preventDefault()
                                            }
                                        }
                                        if (
                                            rules['maxLength'] !== undefined &&
                                            value !== undefined &&
                                            value !== null
                                        ) {
                                            if (
                                                rules['maxLength'].value ===
                                                value.length
                                            ) {
                                                e.preventDefault()
                                            }
                                        }
                                        if (
                                            NOT_ALLOWED_SPECIAL_CHARACTERS.includes(
                                                e.key
                                            )
                                        ) {
                                            if (
                                                rules['allowed'] !== undefined
                                            ) {
                                                if (
                                                    rules['allowed'].includes(
                                                        'special_character'
                                                    ) ||
                                                    rules['allowed'].includes(
                                                        'all_special_character'
                                                    )
                                                ) {
                                                    if (
                                                        !rules[
                                                            'allowed'
                                                        ].includes(
                                                            'all_special_character'
                                                        )
                                                    ) {
                                                        if (
                                                            !ALLOWED_SPECIAL_CHARACTERS.includes(
                                                                e.key
                                                            )
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }
                                                }
                                                if (
                                                    rules['allowed'].includes(
                                                        'dot'
                                                    )
                                                ) {
                                                    if (!(e.key === '.')) {
                                                        e.preventDefault()
                                                    }
                                                }
                                                if (
                                                    rules['allowed'].includes(
                                                        'slash'
                                                    )
                                                ) {
                                                    if (!(e.key === '/')) {
                                                        e.preventDefault()
                                                    }
                                                }
                                            } else {
                                                e.preventDefault()
                                            }
                                        }
                                        if (rules['notAllowed'] !== undefined) {
                                            if (
                                                rules['notAllowed'].includes(
                                                    'space'
                                                )
                                            ) {
                                                if (e.key === ' ') {
                                                    e.preventDefault()
                                                }
                                            }
                                            if (
                                                rules['notAllowed'].includes(
                                                    'numbers'
                                                )
                                            ) {
                                                if (
                                                    NOT_ALLOWED_NUMBER.includes(
                                                        e.key
                                                    )
                                                ) {
                                                    e.preventDefault()
                                                }
                                            }
                                            if (
                                                rules['notAllowed'].includes(
                                                    'startWithZero'
                                                )
                                            ) {
                                                if (
                                                    e.key === '0' &&
                                                    value === ''
                                                ) {
                                                    e.preventDefault()
                                                }
                                            }
                                        }
                                    }}
                                    onMouseEnter={(e) => {
                                        if (
                                            tooltip !== undefined &&
                                            tooltip !== null
                                        ) {
                                            setShowTooltip(true)
                                        }
                                    }}
                                    onMouseLeave={(e) => {
                                        if (
                                            tooltip !== undefined &&
                                            tooltip !== null
                                        ) {
                                            setShowTooltip(false)
                                        }
                                    }}
                                />
                                {showTooltip && (
                                    <span className="tooltip">
                                        {tooltip}{' '}
                                        <span className="tooltipTick"></span>
                                    </span>
                                )}
                            </>
                        )}
                        rules={rules}
                    />
                ) : (
                    <TextField
                        label={label}
                        variant={'standard'}
                        value={inputValue}
                        onChange={(e) => {
                            if (ruleList.indexOf('required') > -1) {
                                if (e.target.value === '') {
                                    setFieldErrorData(true)
                                } else {
                                    setFieldErrorData(false)
                                }
                            }
                            if (type === 'number') {
                                numberValidation(onInputChange, e)
                            } else onInputChange(e)
                        }}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...styles,
                            ...setAdornment,
                            inputProps: {
                                min: 0,
                                step: 'any',
                            },
                        }}
                        size="small"
                        className={`${disabled ? 'disabledInput' : ''} ${
                            errorTrigger !== null && fieldError.status
                                ? 'errorMessageIn'
                                : ''
                        }`}
                        placeholder={
                            errorTrigger !== null
                                ? fieldError.status
                                    ? fieldError.message
                                    : ''
                                : ''
                        }
                        multiline={type === 'textarea' || areaRow !== null}
                        type={type !== 'text' ? type : 'text'}
                        disabled={disabled}
                        onBlur={(e) => {
                            if (ruleList.indexOf('required') > -1) {
                                if (e.target.value === '') {
                                    setFieldErrorData(true)
                                } else {
                                    setFieldErrorData(false)
                                }
                            }
                        }}
                        error={fieldError.status}
                        helperText={
                            errorTrigger !== null
                                ? ''
                                : fieldError.status
                                ? fieldError.message
                                : ''
                        }
                        minRows={areaRow !== null ? areaRow : 3}
                        onKeyPress={(e) => {
                            if (type === 'number') {
                                if (e.key === 'e' || e.key === 'E') {
                                    e.preventDefault()
                                }
                            }
                            if (
                                rules['maxLength'] !== undefined &&
                                inputValue !== undefined &&
                                inputValue !== null
                            ) {
                                if (
                                    rules['maxLength'].value ===
                                    inputValue.length
                                ) {
                                    e.preventDefault()
                                }
                            }
                            if (
                                NOT_ALLOWED_SPECIAL_CHARACTERS.includes(e.key)
                            ) {
                                if (rules['allowed'] !== undefined) {
                                    if (
                                        rules['allowed'].includes(
                                            'special_character'
                                        ) ||
                                        rules['allowed'].includes(
                                            'all_special_character'
                                        )
                                    ) {
                                        if (
                                            !rules['allowed'].includes(
                                                'all_special_character'
                                            )
                                        ) {
                                            if (
                                                !ALLOWED_SPECIAL_CHARACTERS.includes(
                                                    e.key
                                                )
                                            ) {
                                                e.preventDefault()
                                            }
                                        }
                                    }
                                    if (rules['allowed'].includes('dot')) {
                                        if (!(e.key === '.')) {
                                            e.preventDefault()
                                        }
                                    }
                                    if (rules['allowed'].includes('slash')) {
                                        if (!(e.key === '/')) {
                                            e.preventDefault()
                                        }
                                    }
                                } else {
                                    e.preventDefault()
                                }
                            }
                            if (rules['notAllowed'] !== undefined) {
                                if (rules['notAllowed'].includes('space')) {
                                    if (e.key === ' ') {
                                        e.preventDefault()
                                    }
                                }
                                if (
                                    rules['notAllowed'].includes(
                                        'startWithZero'
                                    )
                                ) {
                                    if (e.key === '0' && inputValue === '') {
                                        e.preventDefault()
                                    }
                                }
                            }
                        }}
                    />
                )
            ) : null}
            {type === 'readOnly' && (
                <TextField
                    label={label}
                    variant={'standard'}
                    value={inputValue}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        style: {
                            height: '35px',
                        },
                    }}
                    size="small"
                    type={'text'}
                    className={`readOnlyInput ${!capital && 'noCaps'}`}
                    disabled
                />
            )}
            {type === 'datepicker' && (
                <>
                    {control !== null ? (
                        <Controller
                            name={name}
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <DatePicker
                                    selected={value}
                                    onChange={(e) => {
                                        onChange(e)
                                        if (onInputChange !== null) {
                                            onInputChange(e)
                                        }
                                    }}
                                    onBlur={onBlur}
                                    peekNextMonth
                                    showMonthDropdown
                                    dateFormat="dd/MMM/yyyy"
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={maxDate}
                                    minDate={minDate}
                                    className={disabled ? 'disabledInput' : ''}
                                    disabled={disabled}
                                    popperProps={{
                                        positionFixed: true,
                                    }}
                                    customInput={
                                        <TextField
                                            label={label}
                                            variant={'standard'}
                                            value={inputValue}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                                required:
                                                    ruleList.indexOf(
                                                        'required'
                                                    ) > -1,
                                            }}
                                            InputProps={{
                                                ...styles,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {/* <CalendarIcon
                                                            size={20}
                                                        /> */}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            type={'text'}
                                            required={
                                                ruleList.indexOf('required') >
                                                -1
                                            }
                                            error={
                                                error !== undefined
                                                    ? Boolean(error)
                                                    : false
                                            }
                                            helperText={
                                                error
                                                    ? error.message !== ''
                                                        ? label + error.message
                                                        : ''
                                                    : null
                                            }
                                        />
                                    }
                                />
                            )}
                            rules={rules}
                        />
                    ) : (
                        <DatePicker
                            selected={inputValue}
                            onChange={(e) => {
                                if (onInputChange !== null) {
                                    onInputChange(e)
                                }
                            }}
                            placeholderText={
                                errorTrigger !== null
                                    ? fieldError.status
                                        ? fieldError.message
                                        : ''
                                    : ''
                            }
                            peekNextMonth
                            showMonthDropdown
                            dateFormat="dd/MMM/yyyy"
                            showYearDropdown
                            dropdownMode="select"
                            maxDate={maxDate}
                            minDate={minDate}
                            className={disabled ? 'disabledInput' : ''}
                            disabled={disabled}
                            popperProps={{
                                positionFixed: true,
                            }}
                            customInput={
                                <TextField
                                    label={label}
                                    variant={'standard'}
                                    value={inputValue}
                                    fullWidth
                                    className={`${
                                        disabled ? 'disabledInput' : ''
                                    } ${
                                        errorTrigger !== null &&
                                        fieldError.status
                                            ? 'errorMessageIn'
                                            : ''
                                    }`}
                                    error={fieldError.status}
                                    InputLabelProps={{
                                        shrink: true,
                                        required:
                                            ruleList.indexOf('required') > -1,
                                    }}
                                    InputProps={{
                                        ...styles,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {/* <CalendarIcon size={20} /> */}
                                            </InputAdornment>
                                        ),
                                    }}
                                    size="small"
                                    type={'text'}
                                    required={ruleList.indexOf('required') > -1}
                                />
                            }
                        />
                    )}
                </>
            )}
            {type === 'radio' && (
                <>
                    {control !== null ? (
                        <Controller
                            name={name}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <Box
                                    className={classes.formDiv}
                                    style={{ marginBottom: 2 }}
                                >
                                    <FormLabel
                                        style={{
                                            fontSize: '1rem',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {label}
                                    </FormLabel>
                                    <RadioGroup
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e)
                                            if (onInputChange !== null) {
                                                onInputChange(e)
                                            }
                                        }}
                                        style={{
                                            display: 'inline-block',
                                            marginLeft: 15,
                                            verticalAlign: 2,
                                        }}
                                    >
                                        <div className="radioButton">
                                            {options !== undefined &&
                                            options.length > 0 ? (
                                                <>
                                                    {options.map(
                                                        (item:any, index:any) => (
                                                            <FormControlLabel
                                                                value={
                                                                    item.value
                                                                }
                                                                control={
                                                                    <Radio />
                                                                }
                                                                label={
                                                                    item.value
                                                                }
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <FormControlLabel
                                                        value="Y"
                                                        control={<Radio />}
                                                        label="Yes"
                                                        disabled={disabled}
                                                    />
                                                    <FormControlLabel
                                                        value="N"
                                                        control={<Radio />}
                                                        label="No"
                                                        disabled={disabled}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </RadioGroup>
                                </Box>
                            )}
                            rules={rules}
                        />
                    ) : (
                        <Box
                            className={classes.formDiv}
                            style={{ marginBottom: 2 }}
                        >
                            <FormLabel
                                style={{
                                    fontSize: '1rem',
                                    display: 'inline-block',
                                }}
                            >
                                {label}
                            </FormLabel>
                            <RadioGroup
                                value={inputValue}
                                onChange={(e) => {
                                    if (onInputChange !== null) {
                                        onInputChange(e)
                                    }
                                }}
                                style={{
                                    display: 'inline-block',
                                    marginLeft: 15,
                                    verticalAlign: 2,
                                }}
                            >
                                <div className="radioButton">
                                    {options.length > 0 ? (
                                        <>
                                            {options.map((item:any, index:any) => (
                                                <FormControlLabel
                                                    value={item.value}
                                                    control={<Radio />}
                                                    label={item.value}
                                                    disabled={disabled}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <FormControlLabel
                                                value="Y"
                                                control={<Radio />}
                                                label="Yes"
                                                disabled={disabled}
                                            />
                                            <FormControlLabel
                                                value="N"
                                                control={<Radio />}
                                                label="No"
                                                disabled={disabled}
                                            />
                                        </>
                                    )}
                                </div>
                            </RadioGroup>
                        </Box>
                    )}
                </>
            )}
            {type === 'checkbox' && (
                <>
                    {control !== null ? (
                        <Controller
                            name={name}
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Box
                                        style={{
                                            display: 'inline-flex',
                                            width: '100%',
                                        }}
                                        className={classes.formDiv}
                                        onMouseEnter={(e) => {
                                            if (
                                                tooltip !== undefined &&
                                                tooltip !== null
                                            ) {
                                                setShowTooltip(true)
                                            }
                                        }}
                                        onMouseLeave={(e) => {
                                            if (
                                                tooltip !== undefined &&
                                                tooltip !== null
                                            ) {
                                                setShowTooltip(false)
                                            }
                                        }}
                                    >
                                        {/* {sideLabel ? (
                                            <Box className="checkBoxSide">
                                                <CheckboxWithLabel
                                                    label={label}
                                                    checked={value}
                                                    onChange={(e) => {
                                                        onChange(e)
                                                        if (
                                                            onInputChange !==
                                                            null
                                                        ) {
                                                            onInputChange(e)
                                                        }
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </Box>
                                        ) : ( */}
                                            <div>
                                                <FormLabel
                                                    className={
                                                        classes.formLabel
                                                    }
                                                >
                                                    {label}
                                                </FormLabel>
                                                <Checkbox
                                                    checked={value}
                                                    name={name}
                                                    onChange={onChange}
                                                    className={
                                                        classes.checkBoxPlace
                                                    }
                                                    disabled={disabled}
                                                />
                                            </div>
                                        {/* )} */}
                                    </Box>
                                    {showTooltip && (
                                        <span className="tooltip">
                                            {tooltip}{' '}
                                            <span className="tooltipTick"></span>
                                        </span>
                                    )}
                                </>
                            )}
                            rules={rules}
                        />
                    ) : (
                        <Box className={classes.formDiv}>
                            {/* {sideLabel ? (
                                <Box className="checkBoxSide">
                                    <CheckboxWithLabel
                                        label={label}
                                        checked={inputValue}
                                        onChange={(e) => {
                                            if (onInputChange !== null) {
                                                onInputChange(e)
                                            }
                                        }}
                                        disabled={disabled}
                                    />
                                </Box>
                            ) : ( */}
                                {/* <> */}
                                    {label !== '' && (
                                        <FormLabel
                                            className={classes.formLabel}
                                        >
                                            {label}
                                        </FormLabel>
                                    )}
                                    <Checkbox
                                        checked={inputValue}
                                        name={name}
                                        onChange={(e) => {
                                            if (onInputChange !== null) {
                                                onInputChange(e)
                                            }
                                        }}
                                        disabled={disabled}
                                        className={
                                            label !== ''
                                                ? classes.checkBoxPlace
                                                : ''
                                        }
                                    />
                                {/* </> */}
                            {/* )} */}
                        </Box>
                    )}
                </>
            )}
            {type === 'switch' && (
                <Controller
                    name={name}
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <>
                            <FormLabel className={classes.formLabel}>
                                {label}
                            </FormLabel>
                            <Switch
                                checked={value}
                                name={name}
                                onChange={onChange}
                            />
                        </>
                    )}
                    rules={rules}
                />
            )}
            {type === 'autocomplete' && (
                <>
                    {control !== null ? (
                        <Controller
                            name={name}
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <Box style={{ position: 'relative' }}>
                                    <Autocomplete
                                        data-testid={label}
                                        options={options}
                                        value={value !== undefined ? value : {}}
                                        disableClearable={disableClearable}
                                        classes={
                                            absoluteWidth
                                                ? { paper: classes.paper }
                                                : {}
                                        }
                                        getOptionLabel={(option) =>
                                            getOptionLabelLogic(option)
                                        }
                                        renderOption={(option) =>
                                            getOptionLabelLogic(option)
                                        }
                                        getOptionSelected={(option: any) =>
                                            getOptionLabelLogic(option)
                                        }
                                        renderInput={(params: any) => (
                                            <TextField
                                                error={
                                                    error !== undefined
                                                        ? Boolean(error)
                                                        : false
                                                }
                                                helperText={
                                                    error
                                                        ? error.message !== ''
                                                            ? label +
                                                              error.message
                                                            : ''
                                                        : null
                                                }
                                                {...params}
                                                InputLabelProps={{
                                                    required:
                                                        ruleList.indexOf(
                                                            'required'
                                                        ) > -1,
                                                }}
                                                className={
                                                    disabled
                                                        ? 'disabledInput'
                                                        : ''
                                                }
                                                label={label}
                                                variant="standard"
                                                size="small"
                                            />
                                        )}
                                        onChange={onFieldValueChange(onChange)}
                                        onBlur={onTextValueBlur(onBlur)}
                                        disabled={disabled}
                                        freeSolo={remote || freeText}
                                        onInputChange={(e) => {
                                            if (freeText) {
                                                onFieldValueChangeFree(
                                                    onChange,
                                                    e
                                                )
                                            }
                                            if (
                                                onTextChange !== null &&
                                                e !== null
                                            ) {
                                                // debounced(e.target['value'])
                                            }
                                        }}
                                        loading={isLoading}
                                        clearOnBlur={true}
                                    />
                                    {isLoading && remote ? (
                                        <CircularProgress
                                            size={20}
                                            style={{
                                                position: 'absolute',
                                                right: 10,
                                                top: 23,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            )}
                            rules={rules}
                        />
                    ) : (
                        <Autocomplete
                            data-testid={label}
                            PopperComponent={PopperMy}
                            options={options}
                            disabled={disabled}
                            onOpen={onOpen}
                            value={inputValue}
                            disableClearable={disableClearable}
                            getOptionDisabled={(option) =>
                                disabledValue !== undefined &&
                                disabledValue.length > 0
                                    ? disabledValue.includes(option[optionKey])
                                    : false
                            }
                            getOptionLabel={(option) =>
                                getOptionLabelLogic(option)
                            }
                            renderOption={(option) =>
                                getOptionLabelLogic(option)
                            }
                            getOptionSelected={(option: any) =>
                                getOptionLabelLogic(option)
                            }
                            renderInput={(params) => (
                                <TextField
                                    className={`${
                                        disabled ? 'disabledInput' : ''
                                    } ${
                                        errorTrigger !== null &&
                                        fieldError.status
                                            ? 'errorMessageIn'
                                            : ''
                                    }`}
                                    {...params}
                                    label={label}
                                    variant="standard"
                                    size="small"
                                    error={fieldError.status}
                                    helperText={
                                        errorTrigger !== null
                                            ? ''
                                            : fieldError.status
                                            ? fieldError.message
                                            : ''
                                    }
                                    placeholder={
                                        errorTrigger !== null
                                            ? fieldError.status
                                                ? fieldError.message
                                                : ''
                                            : ''
                                    }
                                />
                            )}
                            loading={isLoading}
                            onChange={(e, data) => {
                                if (ruleList.indexOf('required') > -1) {
                                    if (Object.keys(data).length === 0) {
                                        setFieldErrorData(true)
                                    } else {
                                        setFieldErrorData(false)
                                    }
                                }
                                onInputChange(data)
                            }}
                            onBlur={(e) => {
                                if (
                                    ruleList.indexOf('required') > -1 &&
                                    inputValue !== null
                                ) {
                                    if (Object.keys(inputValue).length === 0) {
                                        setFieldErrorData(true)
                                    } else {
                                        setFieldErrorData(false)
                                    }
                                }
                            }}
                        />
                    )}
                </>
            )}
        </Grid>
    )
}
CustomField.defaultProps = {
    options: [],
    optionKey: '',
    control: null,
    inputValue: null,
    onInputChange: null,
    onBlur: null,
    isLoading: false,
    disabled: false,
    ruleList: [],
    maxDate: null,
    minDate: null,
    ukey: null,
    areaRow: null,
    onInputBlur: null,
    remote: false,
    onTextChange: null,
    capital: false,
    errorTrigger: null,
    onOpen: null,
    autoSize: false,
    sideLabel: false,
    disableClearable: true,
    width: 0,
    freeText: false,
    disabledValue: [],
}
export default CustomField
