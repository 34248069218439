import { useEffect, useState } from 'react';
// import { EVENTS } from "./model/events";
// import arSA from "date-fns/locale/ar-SA";
import { Scheduler } from "../../lib/Scheduler";
// import useFetch from './lib/networks/index'
// import {APPOINTMENT_GET} from './lib/networks/endpoints'
import * as Endpoints from '../../lib/networks/endpoints'
import SideBarWrapper from '../../common/layouts/SideBarWrapper'
import { useFetchAPI } from '../../lib/networks/useFetchAPI'
import {
    SYSTEM_PAGE_TITLE,
} from '../../util/constants'
// import axios from 'axios'
const Appointment = () => {
  const [events, setEvents] = useState<any[]>([]);
  // const [events, setEvents] = useState(EVENTS);

  const {
    execute: getAppointment,
    response: getAppointmentResponse,
    loading: getAppointmentLoading,
} = useFetchAPI(Endpoints.APPOINTMENT_GET, {
    method: 'GET',
})

useEffect(() => {
        document.title = SYSTEM_PAGE_TITLE.APPOINTMENT
        getAppointment()
},[])

useEffect(() => {
  if (getAppointmentLoading === false && getAppointmentResponse) {
      let { status, data } = getAppointmentResponse
      console.log('test',data,getAppointmentResponse)
      // if(data){
      // console.log(data)
      for(let date of getAppointmentResponse){
        date.start = new Date(date.start)
        date.end = new Date(date.end)
  }
  setEvents(getAppointmentResponse)}
  // }
},[getAppointmentLoading,getAppointmentResponse])
//   useEffect(() => {
//   axios.get(APPOINTMENT_GET)
//   .then(res => {
//     if(res)
//     for(let date of res.data){
//     date.start = new Date(date.start)
//     date.end = new Date(date.end)
//   }
//      setEvents(res.data)
//   })
//   .catch(err => {

//   })
// },[])

const today = new Date()
const month = today.getMonth()+1
  return (
    <SideBarWrapper>
    <Scheduler
      dialogMaxWidth="sm"
      // loading={loading}
      // view="month"
      events={events}
      // selectedDate={new Date()}
      selectedDate={new Date('"'+today.getFullYear() +" " + month +" " + today.getDate()+'"')}

      // selectedDate={new Date(2022 ,4, 23)}
      // selectedDate={new Date("2022 5 23")}
      // height={800}
      // week={{
      //   weekDays: [0, 1, 2, 3, 4, 5],
      //   weekStartOn: 6,
      //   startHour: 8,
      //   endHour: 17,
      //   step: 20,
      // }}
      // month={null}
      // day={null}
      // day={{
      //   startHour: 8,
      //   endHour: 18,
      //   step: 20,
      // }}
      // remoteEvents={async (query) => {
      //   await new Promise((res, rej) => {
      //     setTimeout(() => {
      //       // setEvents(EVENTS);
      //       res("");
      //     }, 1000);
      //   });
      //   // return null;
      //   // return EVENTS;
      // }}
      resources={[
        {
          admin_id: 1,
          title: "Dr. Anwar",
          mobile: "5556667770",
          avatar: "./team/Dr.Sajwani.jpeg",
          color: "#ab2d2d",
        },
        {
          admin_id: 2,
          title: "Dr. Ahmed",
          mobile: "555666777",
          avatar: "./team/Dr.Ahmed-Moatassem.jpeg",
          color: "#58ab2d",
        },
        {
          admin_id: 3,
          title: "Dr. Betram",
          mobile: "555666777",
          avatar: "./team/Dr.Meyer.png",
          color: "#a001a2",
        },
        {
          admin_id: 4,
          title: "Dr.Mohammed-El-Hafez",
          mobile: "555666777",          
          avatar: "./team/Dr.Mohammed-El-Hafez.jpeg",
          color: "#08c5bd",
        },
        {
          admin_id: 5,
          title: "Dr.Sawaf",
          mobile: "555666777",          
          avatar: "./team/Dr.Sawaf.png",
          color: "#08c5bd",
        },
        {
          admin_id: 6,
          title: "Dr.Sandip-Mitra",
          mobile: "555666777",          
          avatar: "./team/Dr.Sandip-Mitra.jpg",
          color: "#08c5bd",
        }
      ]}
      resourceFields={{
        idField: "admin_id",
        textField: "title",
        subTextField: "mobile",
        avatarField: "avatar",
        colorField: "color",
      }}
      // resourceViewMode="tabs"
      // recourseHeaderComponent={(recourse) => {
      //   console.log(recourse);
      //   return <div>HAHA</div>;
      // }}
      fields={[
        {
          name: "description",
          type: "input",
          config: { label: "Description", multiline: true, rows: 4 },
        },
        {
          name: "admin_id",
          type: "select",
          config: { label: "Doctor", required: true, multiple: "chips" },
          // default: [1, 2],
          options: [
            // {
            //   id: 1,
            //   text: "One",
            //   value: 1,
            // },
            {
              id: 2,
              text: "Dr.Anwar",
              value: 2,
            },
            {
              id: 3,
              text: "Dr.Ahmed",
              value: 3,
            },
            {
              id: 4,
              text: "Dr.Betram",
              value: 4,
            },
          ],
        },
      ]}
      // onConfirm={async (event, action) => {
      //   console.log(action);
      //   return new Promise((res, rej) => {
      //     setTimeout(() => {
      //       res({
      //         ...event,
      //         event_id: event.event_id || Math.random(),
      //         // title: "From Custom",
      //         // start: new Date(new Date().setHours(11)),
      //         // end: new Date(new Date().setHours(18)),
      //       });
      //     }, 1000);
      //   });
      // }}
      // onDelete={async (id) => {
      //   await new Promise((res, rej) => {
      //     setTimeout(() => {
      //       setEvents((prev) => {
      //         return prev.filter((p) => p.event_id !== id);
      //       });
      //       res("");
      //     }, 1000);
      //   });
      // }}
      // customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
      // viewerExtraComponent={(fields, e) => {
      //   return (
      //     <div>
      //       {Array.from("a".repeat(50)).map((a, i) => (
      //         <div key={i}>Extra</div>
      //       ))}
      //     </div>
      //   );
      //   // console.log(fields, e);
      //   // return (
      //   //   <div>
      //   //     {fields.map((a, i) => (
      //   //       <div key={i}>{e.description}</div>
      //   //     ))}
      //   //   </div>
      //   // );
      // }}
      // viewerTitleComponent={(event) => <>{event.title}</>}
      // direction="rtl"
      // locale={arSA}
      // onEventDrop={async (time, updated) => {
      //   return new Promise((res) => {
      //     setTimeout(() => {
      //       setEvents((prev: any) => {
      //         return prev.map((e) =>
      //           e.event_id === updated.event_id ? updated : e
      //         );
      //       });
      //       res();
      //     }, 1000);
      //   });
      // }}
    />
    </SideBarWrapper>
  );
};
export default Appointment
