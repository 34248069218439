import { Grid, Box, Button, CircularProgress } from 'mashreqdigital_materialui'
import CustomField from '../../components/customfield'
import React, { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    API_STATUS_ERROR,
    LOGOUT_MESSAGE,
    SYSTEM_PAGE_TITLE,
} from '../../util/constants'
// import RCMSLogo from '../../assets/images/logo.png'
import MashreqLogo from '../../assets/images/logo.png'
import SideImage from '../../assets/images/doctor-01.png'
import { Link,useNavigate } from 'react-router-dom'
// import { encryptString } from '../../util/security'
import { useFetchAPI } from '../../lib/networks/useFetchAPI'
import * as Endpoints from '../../lib/networks/endpoints'
import { getErrorMessage, loginFormatDate, showToast } from '../../util/helper'
import * as appContextAction from '../../lib/context/actions/appContextActions'
import { DispatchContext, StateContext } from '../../lib/context/context'
// import { useHistory } from 'react-router-dom'
import * as RouteConfig from '../../routes/config'

const Login = () => {
    useEffect(() => {
        document.title = SYSTEM_PAGE_TITLE.LOGIN_SCREEN
    }, [])

    const history = useNavigate();
    const appContextDispatch = useContext(DispatchContext)
    const contextData = React.useContext(StateContext)

    useEffect(() => {
        if (contextData !== null && contextData.authuser !== undefined) {
            if (
                Object.keys(contextData.authuser).length > 0 &&
                contextData.authuser.accessToken
            ) {
                showToast(
                    'success',
                    LOGOUT_MESSAGE +
                        contextData.authuser.loggedInSession +
                        ' is expired.'
                )
            }
        }
        appContextDispatch(appContextAction.addAuthenticateUser({}))
        appContextDispatch(appContextAction.addApplicationDataGlobal([]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContextDispatch])

    const { control, handleSubmit } = useForm({
        mode: 'onTouched',
    })

    const {
        execute: checkLogin,
        response: checkLoginResponse,
        loading: checkLoginLoading,
    } = useFetchAPI(Endpoints.APPOINTMENT_GET, {
        method: 'POST',
    })

    useEffect(() => {
        if (checkLoginLoading === false && checkLoginResponse) {
            let { status, errorCode, data } = checkLoginResponse
            if (status === API_STATUS_ERROR) {
                showToast('error', getErrorMessage(errorCode))
            } else {
                if (data.authenticated) {
                    appContextDispatch(
                        appContextAction.addAuthenticateUser({
                            accessToken: data.sessionToken,
                            loginID: data.username,
                            fullName: data.fullName,
                            entitlements: data.entitlements,
                            staffId: data.staffId,
                            username: data.username,
                            isSupervisor: true,
                            loggedInSession: loginFormatDate(new Date()),
                            phone: data.phone,
                            email: data.email,
                            branchId: data.branchId,
                            channelId: data.channelId,
                        })
                    )
                    history(RouteConfig.DASHBOARD)
                } else {
                    showToast(
                        'error',
                        getErrorMessage('RCMS-LDAP-AUTH-ERROR-1005')
                    )
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkLoginResponse, checkLoginLoading])

    const onSubmit = (data:any) => {
        history(RouteConfig.APPOINTMENT)

        // let encryptedPassword = encryptString(data.password)
        // checkLogin({
        //     data: {
        //         userName: data.username,
        //         passCode: encryptedPassword,
        //     },
        // })
    }
    return (
        <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ background: '#FFF' }}
        >
            <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{ position: 'relative' }}
            >
                <Grid item
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ padding: '0px 15px', position: 'absolute' }}
                >
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <img
                            // src={''}
                            src={MashreqLogo}
                            className="mashreqNewLogo"
                            alt="mashreqLogo"
                        />
                    </Grid>
                    {/* <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ textAlign: 'right' }}
                    >
                        <img
                            src={RCMSLogo}
                            // src={''}
                            className="loginLogo"
                            alt="mashreqLogo"
                        />
                    </Grid> */}
                </Grid>
                <div className="loginBox">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span className="loginText1">
                                    Sign in to Clinic System
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <span className="loginText2">
                                    Enter your details below.
                                </span>
                            </Grid>

                            <Box style={{ marginTop: 20, width: '100%' }}>
                                <CustomField
                                    type={'text'}
                                    name={'username'}
                                    label={'Username'}
                                    control={control||''}
                                    grid={12}
                                    ruleList={['required']}
                                />
                            </Box>
                            <Box style={{ marginTop: 10, width: '100%' }}>
                                <CustomField
                                    type={'password'}
                                    name={'password'}
                                    label={'Password'}
                                    control={control||''}
                                    grid={12}
                                    ruleList={['required']}
                                />
                            </Box>
                            <Box
                                style={{
                                    marginTop: 10,
                                    marginLeft: 5,
                                    marginRight: 5,
                                    width: '100%',
                                }}
                            >
                                <Button
                                    type={'submit'}
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{
                                        marginTop: 10,
                                        width: '100%',
                                    }}
                                    disabled={checkLoginLoading}
                                >
                                    {checkLoginLoading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        'Login'
                                    )}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="loginText3"
                        >
                            <span>
                                &copy; Copyright Clinic 2022. All rights
                                reserved.
                            </span>
                        </Grid>
                    </form>
                </div>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <div className="whatsnewText">
                    <Link
                        to="system/changelog"
                        style={{
                            color: '#ff5e00',
                            padding: 5,
                            fontFamily: 'Gilroy-Bold',
                        }}
                    >
                        What's new ✨
                    </Link>
                </div>
                <img
                    // src={''}
                    src={SideImage}
                    className="loginSideImage"
                    alt="SideImage"
                />
            </Grid>
        </Grid>
    )
}

export default Login
