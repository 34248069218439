export enum GLOBAL_ERROR_KEYS {
    COMMON_ERROR = 'COMMON_ERROR',
    TIMEOUT = 'TIMEOUT',
    API_FAILURE = 'API_FAILURE',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    COMMUNICATION_POPUP_ERROR = 'COMMUNICATION_POPUP_ERROR',
    BAD_REQUEST = 'BAD_REQUEST',
    GLOBAL_SERVER_ERROR = 'GLOBAL_SERVER_ERROR',
    RCMS_PROXY_ERROR_1001 = 'RCMS-PROXY-ERROR-1001',
    RCMS_PROXY_ERROR_1002 = 'RCMS-PROXY-ERROR-1002',
    RCMS_PROXY_ERROR_1003 = 'RCMS-PROXY-ERROR-1003',
    RCMS_PROXY_ERROR_1004 = 'RCMS-PROXY-ERROR-1004',
    RCMS_PROXY_ERROR_1005 = 'RCMS-PROXY-ERROR-1005',
    RCMS_PROXY_ERROR_1006 = 'RCMS-PROXY-ERROR-1006',
    RCMS_PROXY_ERROR_1007 = 'RCMS-PROXY-ERROR-1007',
    RCMS_PROXY_ERROR_1008 = 'RCMS-PROXY-ERROR-1008',
    'RCMS-AUTH-ERROR-1006' = 'RCMS-AUTH-ERROR-1006',
    'RCMS-AUTH-ERROR-1007' = 'RCMS-AUTH-ERROR-1007',
    'RCMS-LDAP-AUTH-ERROR-1005' = 'RCMS-LDAP-AUTH-ERROR-1005',
    'RCMS-5000' = 'RCMS-5000',
    RCMS_LENDING_1000 = 'RCMS-LENDING-APP-1000',
    RCMS_CORE_TYPE_NOT_FOUND_LOVS = 'RCMS_CORE_TYPE_NOT_FOUND_LOVS',
    RCMS_CATEGORY_NOT_FOUND = 'RCMS_CATEGORY_NOT_FOUND',
    RCMS_MODULE_CODE_NOT_FOUND = 'RCMS_MODULE_CODE_NOT_FOUND',
    RCMS_NOT_VALID_MODULE_CODE = 'RCMS_NOT_VALID_MODULE_CODE',
    RCMS_PRODUCT_TYPE_NOT_FOUND = 'RCMS_PRODUCT_TYPE_NOT_FOUND',
    RCMS_PRODUCT_DETAILS_NOT_FOUND = 'RCMS_PRODUCT_DETAILS_NOT_FOUND',
    PRIMARY_CUSTOMER_NOT_AVAILABLE = 'PRIMARY_CUSTOMER_NOT_AVAILABLE',
    INTERFACE_ERROR_INVALID_PARAMETER_VALUES = 'INTERFACE_ERROR_INVALID_PARAMETER_VALUES',
    'RCMS-LNDAPP-APP-CREATION-ERROR' = 'RCMS-LNDAPP-APP-CREATION-ERROR',
    'STATEMENT_PERIOD_LIMIT_EXCEEDED' = 'STATEMENT_PERIOD_LIMIT_EXCEEDED',
    SYSTEM_ERROR = 'SYSTEM_ERROR',
    'ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_AVAILABLE' = 'ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_AVAILABLE',
    'ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_EXIST' = 'ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_EXIST',
    'AECB_CUSTOMER_NOT_FOUND' = 'AECB_CUSTOMER_NOT_FOUND',
    APPLICATION_STATEMENT_REQUEST_NOT_VALID = 'APPLICATION_STATEMENT_REQUEST_NOT_VALID',
    PRIMARY_APPLICANT_NOT_FOUND = 'PRIMARY_APPLICANT_NOT_FOUND',
    CUSTOMER_DETAILS_NOT_FOUND = 'CUSTOMER_DETAILS_NOT_FOUND',
    OMDM_LITE_CALL_NOT_SUCCESS = 'OMDM_LITE_CALL_NOT_SUCCESS',
    OMDM_LITE_CALL_ERROR = 'OMDM_LITE_CALL_ERROR',
    OMDM_ERROR = 'OMDM_ERROR',
    OMDM_INTERNAL_SERVER_ERROR = 'OMDM_INTERNAL_SERVER_ERROR',
    LAS_PRODUCT_DETAILS_ERROR = 'LAS_PRODUCT_DETAILS_ERROR',
    RCMS_LENDING_APPLICATION_ID_NOT_FOUND = 'RCMS_LENDING_APPLICATION_ID_NOT_FOUND',
    AECB_REPORT_DATA_NOT_FOUND = 'AECB_REPORT_DATA_NOT_FOUND',
    'EAI-GSL-BRK-999' = 'EAI-GSL-BRK-999',
    'CLS_AECB_ERROR' = 'CLS_AECB_ERROR',
    'RCMS_MW_SERVICE_ERROR' = 'RCMS_MW_SERVICE_ERROR',
    'MW_CDS_ERROR' = 'MW_CDS_ERROR',
    'MW_CCSS_ERROR' = 'MW_CCSS_ERROR',
    'MW_LADS_ERROR' = 'MW_LADS_ERROR',
    'SIMULATED_RESPONSE_NOT_CONFIGURED' = 'SIMULATED_RESPONSE_NOT_CONFIGURED',
    'EXTERNAL_SERVICE_ERROR' = 'EXTERNAL_SERVICE_ERROR',
    'LAS_PRODUCT_EMPTY' = 'LAS_PRODUCT_EMPTY',
    'INVALID_CIF_OR_CUSTOMER_ID' = 'INVALID_CIF_OR_CUSTOMER_ID',
    'RCMS_LENDING_APPLICATION_NOT_FOUND' = 'RCMS_LENDING_APPLICATION_NOT_FOUND',
    'STATEMENT_REQUEST_ALREADY_EXISTS' = 'STATEMENT_REQUEST_ALREADY_EXISTS',
    'CONSENT_DATE_USED_ALREADY' = 'CONSENT_DATE_USED_ALREADY',
    'CPV_NOT_AVAILABLE' = 'CPV_NOT_AVAILABLE',
    'LAS_EMPLOYER_DETAILS_ERROR' = 'LAS_EMPLOYER_DETAILS_ERROR',
    'LBS_SUPP_CARD_DISBURSAL' = 'LBS_SUPP_CARD_DISBURSAL',
    'MW_CCDS_ERROR' = 'MW_CCDS_ERROR',
    'LDS_NONDIGITAL_CARD_DISBURSAL' = 'LDS_NONDIGITAL_CARD_DISBURSAL',
    'RCMS_CACHE_KEY' = 'RCMS_CACHE_KEY',
    'NAME_SCREENING_CALL_FAILED' = 'NAME_SCREENING_CALL_FAILED',
    'LAS_PRODUCT_DETAILS_EMPTY' = 'LAS_PRODUCT_DETAILS_EMPTY',
    'MW_LADS_TIMEOUT' = 'MW_LADS_TIMEOUT',
    'MW_CCSS_TIMEOUT' = 'MW_CCSS_TIMEOUT',
    'MW_CLI_ERROR' = 'MW_CLI_ERROR',
    'MW_CCDS_TIMEOUT' = 'MW_CCDS_TIMEOUT',
    'MW_IDS_TIMEOUT' = 'MW_IDS_TIMEOUT',
    'MW_IDS_ERROR' = 'MW_IDS_ERROR',
    'MW_LCDS_TIMEOUT' = 'MW_LCDS_TIMEOUT',
    'MW_LCDS_ERROR' = 'MW_LCDS_ERROR',
    'MW_MMC_TIMEOUT' = 'MW_MMC_TIMEOUT',
    'MW_MMC_ERROR' = 'MW_MMC_ERROR',
    'MW_VDS_TIMEOUT' = 'MW_VDS_TIMEOUT',
    'MW_VDS_ERROR' = 'MW_VDS_ERROR',
    'MW_MMPS_TIMEOUT' = 'MW_MMPS_TIMEOUT',
    'LAS_TIMEOUT' = 'LAS_TIMEOUT',
    'LAS_APP_DETAILS_ERROR' = 'LAS_APP_DETAILS_ERROR',
    'LAS_APP_DETAILS_CUSTOMER_NOT_FOUN' = 'LAS_APP_DETAILS_CUSTOMER_NOT_FOUN',
    'LAS_APP_DETAILS_EMPLOYMENT_NOT_FO' = 'LAS_APP_DETAILS_EMPLOYMENT_NOT_FO',
    'LAS_PROPERTY_DETAILS_ERROR' = 'LAS_PROPERTY_DETAILS_ERROR',
    'LAS_CC_DETAILS_ERROR' = 'LAS_CC_DETAILS_ERROR',
    'LAS_PL_DETAILS_ERROR' = 'LAS_PL_DETAILS_ERROR',
    'LAS_ROD_DETAILS_ERROR' = 'LAS_ROD_DETAILS_ERRORR',
    'ES_TIMEOUT' = 'ES_TIMEOUT',
    'ES_EMPLOYER_DETAILS_ERROR' = 'ES_EMPLOYER_DETAILS_ERROR',
    'CLS_TIMEOUT' = 'CLS_TIMEOUT',
    'CLS_LIABILITIES_ERROR' = 'CLS_LIABILITIES_ERROR',
    'CLS_FINAL_DSR_ERROR' = 'CLS_FINAL_DSR_ERROR',
    'CLS_CCH_NOT_FOUND_ERROR' = 'CLS_CCH_NOT_FOUND_ERROR',
    'CLS_IH_NOT_FOUND_ERROR' = 'CLS_IH_NOT_FOUND_ERROR',
    'CLS_NIH_NOT_FOUND_ERROR' = 'CLS_NIH_NOT_FOUND_ERROR',
    'INTERNAL_SERVER_ERR' = 'INTERNAL_SERVER_ERR',
    'OMDM_DEPENDENCY_CALL' = 'OMDM_DEPENDENCY_CALL',
    'OMDM_CONNECTION' = 'OMDM_CONNECTION',
    'OMDM_TIMEOUT' = 'OMDM_TIMEOUT',
    'OMDM_PAYLOAD_MARSHALL' = 'OMDM_PAYLOAD_MARSHALL',
    'OMDM_PAYLOAD_UNMARSHALL' = 'OMDM_PAYLOAD_UNMARSHALL',
    'OMDM_AUTH_ERROR' = 'OMDM_AUTH_ERROR',
    'LBS_INTERNAL_SERVER_ERROR' = 'LBS_INTERNAL_SERVER_ERROR',
    'LBS_INVALID_STAGE_CODE' = 'LBS_INVALID_STAGE_CODE',
    'LDS_TIMEOUT' = 'LDS_TIMEOUT',
    'LDS_SUPP_CARD_DISBURSAL' = 'LDS_SUPP_CARD_DISBURSAL',
    'LDS_NON_DIGITAL_STANDALONE_CARD_DISBURSAL' = 'LDS_NON_DIGITAL_STANDALONE_CARD_DISBURSAL',
    'LDS_BUNDLED_DISBURSAL' = 'LDS_BUNDLED_DISBURSAL',
    'LDS_INTERNAL_SETTLEMENT' = 'LDS_INTERNAL_SETTLEMENT',
    'LDS_EXTERNAL_SETTLEMENT' = 'LDS_EXTERNAL_SETTLEMENT',
    'LAS_CLI_DETAILS_ERROR' = 'LAS_CLI_DETAILS_ERROR',
    'LBS_TASK_NOT_FOUND' = 'LBS_TASK_NOT_FOUND',
    'LBS_TASK_ID_EMPTY' = 'LBS_TASK_ID_EMPTY',
    'LBS_USER_ID_EMPTY' = 'LBS_USER_ID_EMPTY',
    'LBS_SUPERVISOR_NOT_FOUND' = 'LBS_SUPERVISOR_NOT_FOUND',
    'BPM_AUTH_ERROR' = 'BPM_AUTH_ERROR',
    'RCMS_MW_SERVICE_ERROR_LIABILITY_FETCH' = 'RCMS_MW_SERVICE_ERROR_LIABILITY_FETCH',
    'SALARY_DETAIL_DOES_NOT_EXIST' = 'SALARY_DETAIL_DOES_NOT_EXIST',
    'UAEFTS_TRANSACTION_DOEST_NOT_EXIST_FOR_IBAN' = 'UAEFTS_TRANSACTION_DOEST_NOT_EXIST_FOR_IBAN',
    'LDS_BUNDLED_DISBURSAL_ERROR' = 'LDS_BUNDLED_DISBURSAL_ERROR',
    'INVALID_ACCOUNT_NUMBER' = 'INVALID_ACCOUNT_NUMBER',
    'CUSTOMER_NOT_FOUND_IN_PRIME' = 'CUSTOMER_NOT_FOUND_IN_PRIME',
}

export const GLOBAL_ERROR_MESSAGE:any = {
    ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_AVAILABLE:
        'Statement Summary does not exists for this Request!',
    ACCOUNT_STATEMENT_SUMMARY_DOES_NOT_EXIST:
        'Statement Summary does not exists for this Request!',
    APPLICATION_STATEMENT_REQUEST_NOT_VALID: 'Statement Request is not valid!',
    COMMON_ERROR:
        'Something went wrong. Contact system administrator if issue persists.',
    TIMEOUT: 'Apologies, there was an error.',
    INTERNAL_SERVER_ERROR:
        'Problem with Internet connection, please check your network',
    API_FAILURE: 'Unable to get details! Please try again',
    BAD_REQUEST: 'Apologies, there was an error.',
    GLOBAL_SERVER_ERROR: 'Apologies, there was an error, Please try again!',
    RCMS_PROXY_ERROR_1001:
        'An error occured during getting username from token.',
    RCMS_PROXY_ERROR_1002: 'Token is expired and not valid anymore.',
    RCMS_PROXY_ERROR_1003:
        'Authentication Failed. Username or Password not valid.',
    RCMS_PROXY_ERROR_1004:
        'Exception Occur while retrieving  user DTO from rcms-core service',
    RCMS_PROXY_ERROR_1005:
        'Exception Occur while validating credentials in LDAP',
    RCMS_PROXY_ERROR_1006: 'Exception Occur while validating credentials',
    RCMS_PROXY_ERROR_1007:
        'Index 0 out of bounds for length 0 -Exception Occur while validating credentials',
    RCMS_PROXY_ERROR_1008: 'No Authorization provided for the request',
    'RCMS-5000': ' Something went wrong with external service',
    RCMS_LENDING_1000:
        'Some Technical Error is occurred. Please check the logs for more details',
    RCMS_CORE_TYPE_NOT_FOUND_LOVS:
        'Type is not found in the request for retrieve the LOVs',
    RCMS_CATEGORY_NOT_FOUND:
        'Category is not found in the request for retrieve the Country list',
    RCMS_MODULE_CODE_NOT_FOUND:
        'Module is not found in the request for retrieve the Product list',
    RCMS_NOT_VALID_MODULE_CODE: 'Not a valid module code',
    RCMS_PRODUCT_TYPE_NOT_FOUND:
        'Product type is not found in the request for retrieve the products',
    RCMS_PRODUCT_DETAILS_NOT_FOUND:
        'Product details is not found in the request for retrieve the campaigns',
    PRIMARY_CUSTOMER_NOT_AVAILABLE:
        'There should be at-least one primary customer available for Application',
    INTERFACE_ERROR_INVALID_PARAMETER_VALUES: 'Apologies, there was an error.',
    SYSTEM_ERROR: 'Something went wrong with external service',
    'EAI-GSL-BRK-999': 'Something went wrong with external service',
    EXTERNAL_SERVICE_ERROR: 'Something went wrong with external service',
    'RCMS-LNDAPP-APP-CREATION-ERROR':
        'Existing Application found for this customer with active status',
    AECB_CUSTOMER_NOT_FOUND: "We couldn't find AECB Customer Data!",
    STATEMENT_PERIOD_LIMIT_EXCEEDED: 'Max allowed statement period is 180 days',
    PRIMARY_APPLICANT_NOT_FOUND: 'Primary applicant not found!',
    CUSTOMER_DETAILS_NOT_FOUND: 'Customer details not found!',
    OMDM_LITE_CALL_NOT_SUCCESS: 'OMDM Lite Call is Unsuccessful',
    OMDM_LITE_CALL_ERROR: 'Something went wrong with OMDM Lite call',
    OMDM_ERROR: 'Something went wrong with OMDM call',
    LAS_PRODUCT_DETAILS_ERROR: 'Product details is not found in this request!',
    AECB_REPORT_DATA_NOT_FOUND: 'No AECB data found for this customer!',
    RCMS_LENDING_APPLICATION_ID_NOT_FOUND:
        "The application doesn't exist or invalid on the system",
    CLS_AECB_ERROR: 'AECB Customer data not found!',
    RCMS_MW_SERVICE_ERROR: 'Something went wrong with middleware',
    MW_CCSS_ERROR: 'Middleware Exception on Credit Card Summary Service',
    MW_CCSS_TIMEOUT:
        'Unable to reach Credit Card Summary Middleware Service, due to Timeout',
    MW_CDS_ERROR: 'Middleware Exception on Customer Details Service',
    MW_CDS_TIMEOUT:
        'Unable to reach Linked Customer Middleware Service Details, due to Timeout',
    MW_CCDS_ERROR: 'Middleware Exception on  Credit Card Details Service',
    MW_CCDS_TIMEOUT:
        'Unable to reach Linked Customer Middleware Service Details, due to Timeout',
    MW_LADS_ERROR: 'Middleware Exception on Linked Account Service Details',
    MW_LADS_TIMEOUT:
        'Unable to reach Linked Account Middleware Service Details, due to Timeout',
    MW_CLI_ERROR: 'Middleware Exception on Credit Limit Increase Details',
    MW_CLI_TIMEOUT:
        'Unable to reach Credit Limit Increase Middleware Service Details, due to Timeout',
    MW_IDS_ERROR: 'Middleware Exception on Investment Details Service',
    MW_IDS_TIMEOUT:
        'Unable to reach Investment Details  Middleware Service, due to Timeout',
    MW_LCDS_ERROR: 'Middleware Exception on Linked Account Service Details',
    MW_LCDS_TIMEOUT:
        'Unable to reach Linked Account Service Details Middleware Service, due to Timeout',
    MW_MMC_ERROR: 'Middleware Exception on Customer Details Service',
    MW_MMC_TIMEOUT:
        'Unable to reach Linked Customer Details Middleware Service, due to Timeout',
    MW_VDS_ERROR: 'Middleware Exception on DBridgeVectus Service',
    MW_VDS_TIMEOUT:
        'Unable to reach DBridgeVectus Middleware Service, due to Timeout',
    MW_MMPS_TIMEOUT:
        'Unable to reach MMPDetails Middleware Service, due to Timeout',
    SIMULATED_RESPONSE_NOT_CONFIGURED:
        "AECB simulator List doesn't contains this Application Emirates ID",
    LAS_PRODUCT_EMPTY: 'There are no product found in this application.',
    INVALID_CIF_OR_CUSTOMER_ID:
        'Invalid CIF or Customer ID for this application',
    RCMS_LENDING_APPLICATION_NOT_FOUND:
        'No Application found in the database for given details',
    STATEMENT_REQUEST_ALREADY_EXISTS: 'Statement request exists already',
    CONSENT_DATE_USED_ALREADY:
        'There is a request exist with same consent date!',
    CPV_NOT_AVAILABLE: 'CPV Details not available for this application',
    LAS_EMPLOYER_DETAILS_ERROR: 'Unable to fetch Employer Details',
    LBS_SUPP_CARD_DISBURSAL:
        'Exception on Disbursal Service while creating Supplementary Card',
    LDS_NONDIGITAL_CARD_DISBURSAL:
        'Exception on Non Digital Disbursal while creating Standalone Credit Card',
    RCMS_CACHE_KEY: 'Exception on Master Data! ',
    NAME_SCREENING_CALL_FAILED: 'Error while initiating name screening call!',
    LAS_PRODUCT_DETAILS_EMPTY:
        'There are no product found in this application!',
    OMDM_DEPENDENCY_CALL: 'Something went wrong with OMDM Dependency call',
    OMDM_CONNECTION: 'Something went wrong with OMDM Connection',
    OMDM_TIMEOUT: 'Unable to reach OMDM, due to Timeout',
    OMDM_PAYLOAD_MARSHALL:
        'Internal Server Error while generating the OMDM request payload.',
    OMDM_PAYLOAD_UNMARSHALL:
        'Internal Server Error while parsing the OMDM response payload.',
    LAS_TIMEOUT: 'Unable to reach the application, due to Timeout',
    LAS_APP_DETAILS_ERROR: 'There is an exception in an application',
    LAS_APP_DETAILS_CUSTOMER_NOT_FOUND:
        'No Customer found for this application.',
    LAS_APP_DETAILS_EMPLOYMENT_NOT_FOUND:
        'No Employment found for this application.',
    LAS_PROPERTY_DETAILS_ERROR:
        'There are no property details found in this application.',
    LAS_CLI_DETAILS_ERROR:
        'Credit Limit Increase details not found in this request!',
    LAS_CC_DETAILS_ERROR: 'Credit Card details not found in this request!',
    LAS_PL_DETAILS_ERROR: 'Personal details not found in this request!',
    LAS_ROD_DETAILS_ERRORR: 'ROD details not found in this request!',
    ES_TIMEOUT: 'Unable to reach the application, due to Timeout',
    ES_EMPLOYER_DETAILS_ERROR: 'There is an exception in an Employer Details',
    CLS_TIMEOUT: 'Unable to reach the application, due to Timeout',
    CLS_LIABILITIES_ERROR: 'Liabilities Customer data not found!',
    CLS_FINAL_DSR_ERROR: 'Final DSR Customer data not found!',
    CLS_CCH_NOT_FOUND_ERROR: 'CCH Customer data not found!',
    CLS_IH_NOT_FOUND_ERROR: 'IH Customer data not found!',
    CLS_NIH_NOT_FOUND_ERROR: 'NIH Customer data not found!',
    OMDM_AUTH_ERROR: 'OMDM Authentication Error',
    OMDM_INTERNAL_SERVER_ERROR: 'OMDM Internal Server Error',
    LBS_INVALID_STAGE_CODE:
        'The application is invalid for the given stage code',
    LDS_TIMEOUT: 'Unable to reach the application, due to Timeout',
    LDS_SUPP_CARD_DISBURSAL:
        'Supplementary Card Disbursal not found in this request!',
    LDS_NON_DIGITAL_STANDALONE_CARD_DISBURSAL:
        'Non Digital Standalone card disbursal not found in this request!',
    LDS_BUNDLED_DISBURSAL: 'Bundled Disbursal not found in this request!',
    LDS_INTERNAL_SETTLEMENT: 'Internal Settlement not found in this request!',
    LDS_EXTERNAL_SETTLEMENT: 'External Settlement not found in this request!',
    LBS_TASK_NOT_FOUND: 'There is no task found in this application.',
    LBS_TASK_ID_EMPTY: 'There is no task id found in this application.',
    LBS_USER_ID_EMPTY: 'There is no user id found in this application.',
    LBS_SUPERVISOR_NOT_FOUND:
        'There is no supervisor found in this application.',
    BPM_AUTH_ERROR: 'BPM  Authentication Error',
    RCMS_MW_SERVICE_ERROR_LIABILITY_FETCH:
        'Error in fetching Internal Liabilities',
    SALARY_DETAIL_DOES_NOT_EXIST: 'Salary Details does not exist!',
    UAEFTS_TRANSACTION_DOEST_NOT_EXIST_FOR_IBAN:
        'UAEFTS transaction does not exist with provided IBAN Number',
    LDS_BUNDLED_DISBURSAL_ERROR: 'Error in Bundle Disbursal',
    INVALID_ACCOUNT_NUMBER: 'Invalid Account Number!',
    CUSTOMER_NOT_FOUND_IN_PRIME: 'Customer not found in Prime',
    'RCMS-AUTH-ERROR-1006':
        'Exception Occur while validating credentials in LDAP',
    'RCMS-AUTH-ERROR-1007':
        'Exception Occur while validating credentials in LDAP',
    'RCMS-LDAP-AUTH-ERROR-1005': 'Invalid Authentication!',
}
