import { withStyles } from 'mashreqdigital_materialui'
// import { DIRECTION } from '@mashreq/types'

const GlobalCss = withStyles(({ direction, palette, spacing }) => ({
    '@global': {
        '*, *::before, *::after': {
            margin: 0,
            padding: 0,
        },
        '.tertiary': {
            color: palette.grey,
        },
        '.flipDir': {
            transform: direction === 'rtl' ? 'rotate(-180deg)' : 'none',
        },
        ':focus': {
            outline: 'none',
        },
        '::-ms-clear': {
            width: 0,
            height: 0,
        },
        '::-ms-reveal, ::-webkit-textfield-decoration-container, ::-ms-clear': {
            display: 'none',
        },
        '::-webkit-contacts-auto-fill-button': {
            visibility: 'hidden',
            display: 'none !important',
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
        },
        '::-webkit-scrollbar': {
            backgroundColor: '#fff',
            width: '16px',
            '&-track': {
                backgroundColor: '#fff',
            },
            '&-thumb': {
                backgroundColor: '#babac0',
                borderRadius: '16px',
                border: '4px solid #fff',
            },
            '&-button': {
                display: 'none',
            },
        },
        'input:-internal-autofill-selected': {
            backgroundColor: 'transparent !important',
            color: 'inherit !important',
        },
        'ul.inline': {
            display: 'flex',
            justifyContent: 'center',
            '&>li': {
                '&.footerlist': {
                    padding: `0 ${spacing(2)}px`,
                    cursor: 'pointer',
                    borderRight: `1px solid ${palette?.grey?.[500]}`,
                    '&:last-child': {
                        paddingRight: 0,
                        borderRight: 'none',
                    },
                    '&:first-child': {
                        paddingLeft: 0,
                    },
                },
            },
            '&.border': {
                '&>li': {
                    borderRight: `1px solid ${palette?.grey?.[500]}`,
                    '&:last-child': {
                        borderRight: 'none',
                    },
                },
            },
        },
        'ul.list-style-none': {
            listStyleType: 'none',
        },
        'a, a > *': {
            textDecoration: 'none',
        },
        body: {
            backgroundColor: '#ffffff',
            // "@media (min-width: 1440px)": {
            //   maxWidth: "1440px",
            //   margin: "0 auto"
            // }
        },
        header: {
            '& + div.MuiBox-root': {
                paddingTop: 64,
            },
        },
        footer: {
            padding: '0 33px',
            zIndex: '1100',
        },
    },
}))(() => null)

export default GlobalCss
